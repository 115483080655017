// General
export const LOADER = 'LOADER';
export const EXPERTS_INFORMATION = 'EXPERTS_INFORMATION';
export const CLIENTS_INFORMATION = 'CLIENTS_INFORMATION';
export const APPOINTMENTS_INFORMATION = 'APPOINTMENTS_INFORMATION';
// Login
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const USER_VALID = 'USER_VALID';
// Profile 
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';