import React, { Component } from 'react';
// Externals
import ReactSVG from 'react-svg';
// Images
import user from "./../../../assets/images/user.svg";
import letter from "./../../../assets/images/letter.svg";

class ClientModalProfile extends Component {

  renderLenguages = () => {
    const { t } = this.props;
    if (this.props.client && this.props.client.languages_skill) {
      let languages = this.props.client.languages_skill;
      let languageCard = [];
      if (languages.constructor === Object) {
        Object.entries(languages.languages).map((level) => {
          let languageArray = [];
          let header = `${level[0]}Header`;
          level[1].forEach(element => {
            let language = `<p class="body-description">${element}</p>`;
            languageArray.push(language)
          });
          let currentCard = `
          <div class = "col-12 col-md-4">
          <h3>${t(header)}</h3>
            <div class="card">
              <div class="card-body">
                ${languageArray.join('')}
              </div>
            </div>
          </div >`;
          languageCard.push(currentCard);
          return null;
        });
      }
      return <div className="row align-items-start" dangerouslySetInnerHTML={{ __html: languageCard.sort().join('') }}></div>;
    }
    else {
      return (
        <div>
          <p>{t('CMP_L2')}</p>
        </div>
      );
    }
  }

  renderPayment = () => {
    const { t, client } = this.props;
    const { last_payment_info } = client;
    if (client && last_payment_info) {
      let payment = last_payment_info;
      return (
        <div className="row align-items-start">
          <div className="col-12 col-lg-4 mx-auto card paymentInformation">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center header">
              <h3>{t('paymentHeader')}</h3>
            </div>
            <p className="message">
              <span className="label">{t('card_last4')}</span>
              <span>{payment.card_last4}</span>
            </p>
            <p className="message">
              <span className="label">{t('card_brand')}</span>
              <span>{payment.brand}</span>
            </p>
            <p className="message">
              <span className="label">{t('card_exp_month')}</span>
              <span>{payment.exp_month}</span>
            </p>
            <p className="message">
              <span className="label">{t('card_exp_year')}</span>
              <span>{payment.exp_year}</span>
            </p>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          <p>{t('CMP_L2')}</p>
        </div>
      );
    }
  }

  renderWorkExperience = () => {
    const { t } = this.props;
    if (this.props.client && this.props.client.work_histories.length > 0) {
      let industries = this.props.client.work_histories;
      return (
        <div className="row align-items-start">
          {
            Object.entries(industries).map((industrie) => {
              let from = new Date(industrie[1].from);
              let fromYear = from.getFullYear();
              let toDate = industrie[1].to !== 'present' ? new Date(industrie[1].to) : industrie[1].to;
              let toYear = industrie[1].to !== 'present' ? toDate.getFullYear() : toDate;
              return (
                <div className="col-12 col-md-4 industrieCard" key={industrie[0]}>
                  <div className="card">
                    <div className="card-body">
                      <h3>{industrie[1].job_title}</h3>
                      <h5>{industrie[1].company}</h5>
                      <p className="company">{industrie[1].location}</p>
                      <p className="timeLapse">{fromYear} - {toYear}</p>
                      <p className="field">{industrie[1].industry_field}</p>
                      <p className="website"><a href={`https://${industrie[1].company_website}`} rel="noopener noreferrer" target="_blank">{industrie[1].company_website}</a></p>
                    </div>
                  </div>
                </div >
              )
            })
          }
        </div>
      );
    }
    else {
      return (
        <div>
          <p>{t('CMP_L3')}</p>
        </div>
      );
    }
  }

  renderContact = () => {
    const { client, t } = this.props;
    return (
      <div className="stepComponent contact-information row m-0 w-100 pt-3">
        <div className="row inputsContainer">
          <div id="first_name_container" className="form-group col-12 col-md-4">
            <label htmlFor="first_name">
              <ReactSVG className="svgIcon" src={user} />
            </label>
            <input
              type="text"
              className="form-control"
              name="first_name"
              id="first_name"
              data-tip="User Name"
              data-for="User Name"
              placeholder={t("ciUsername")}
              onChange={e => this.updateUserContact(e)}
              defaultValue={
                client["first_name"]
              }
              readOnly
            />
          </div>
          <div id="email_container" className="form-group col-12 col-md-4">
            <label htmlFor="email">
              <ReactSVG className="svgIcon" src={letter} />
            </label>
            <input
              type="text"
              className="form-control"
              name="email"
              id="email"
              placeholder={t("ciUserEmail")}
              onChange={e => this.updateUserContact(e)}
              defaultValue={client["email"]}
              readOnly
            />
          </div>
          <div id="last_name_container" className="form-group col-12 col-md-4">
            <label htmlFor="last_name">
              <ReactSVG className="svgIcon" src={user} />
            </label>
            <input
              type="text"
              className="form-control"
              name="last_name"
              id="last_name"
              placeholder={t("ciUserLast")}
              onChange={e => this.updateUserContact(e)}
              defaultValue={
                client["last_name"]
              }
              readOnly
            />
          </div>
        </div>
      </div >
    );
  }

  render() {
    const { t, changeClientStatus, currentState, client } = this.props;
    console.log(client);

    const { fetchError } = currentState;
    let userProfile = this.props.client;
    let { first_name, last_name, last_payment_info } = userProfile;
    let full_name = first_name + ' ' + last_name;
    return (
      <div className="ExpertsResultsContainer mt-5">
        <div className="profileBanner">
          <img className="img-fluid rounded-circle userProfileImage" src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/${(userProfile.type).toLowerCase()}/avatar/${userProfile.uuid}/0.jpeg`} alt="user" />
        </div>
        <div className="card">
          <div className="section-title text-center card-body">
            <h2 className="px-3">{t('CMP_L5', { full_name })} </h2>
            <p className="h4">{userProfile.about['title']}</p>
            <div className="row my-3 justify-content-center align-items-center">
              <p className="m-0">{t('ETStatus')} &nbsp;</p>
              <label className="switch">
                <input onChange={() => changeClientStatus(userProfile.id)} type="checkbox" checked={userProfile.active} />
                <span className="slider round"></span>
              </label>
            </div>
            {
              fetchError &&
              <div className="alert alert-danger" role="alert">
                {t('fetchError', { fetchError })}
              </div>
            }
            <p className="about">{userProfile.about['aboutBody']}</p>
            {currentState['expertAvailabilities'] !== undefined &&
              <div className="row">
                {t('CMP_L7')}
              </div>
            }
          </div>
        </div>
        <div className="card">
          <div className="section-title text-center card-body">
            <div className="row justify-content-center align-items-center">
              <p className="m-0 mt-3 h4">{t('ETContactInfo')} &nbsp;</p>
              {this.renderContact()}
            </div>
          </div>
        </div>
        {last_payment_info.card_last4 &&
          <div className="filtersInExpert mt-5">
            {this.renderPayment()}
          </div>
        }
        <div className="section-title text-center card-body">
          <h2 className="px-3">{t('CMP_L9')}</h2>
          <span className="title-border"></span>
        </div>
        <div className="filtersInExpert">
          {this.renderLenguages()}
        </div>
        <div className="section-title text-center card-body">
          <h2 className="px-3">{t('CMP_L10')}</h2>
          <span className="title-border"></span>
        </div>
        <div className="filtersInExpert">
          {this.renderWorkExperience()}
        </div>
      </div>
    );
  }
}

export default ClientModalProfile;