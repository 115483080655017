import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Actions
import {
  loader,
  experts_information,
  appointments_information,
} from "./../../actions/general";
// Services
import {
  getAllExperts,
  toggleExpertStatus,
  getAllUsersBySearch,
  getExpertBalance,
  getExpertAppointments,
  getServices,
  getExpertAvailabilitiesForAppointments,
  updateAppointment,
  getJobFunctions,
  getIndustries,
} from "./../../services/index";
// Components
import ExpertsTable from "./components/ExpertsTable";
import ExpertModalProfile from "./components/ExpertModalProfile";
import TablePagination from "./components/TablePagination";
// Externals imports
import Modal from "react-modal";
import ReactSVG from "react-svg";
import moment from "moment";
import "moment/locale/es";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// Images
import cancel from "./../../assets/images/cancel.svg";
import { DownloadCsv } from "./components/DownloadCsv";

const localizer = momentLocalizer(moment);

Modal.setAppElement("#root");

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      terms_modal: false,
      experts: undefined,
      expertBalance: undefined,
      showToggleModal: undefined,
      showAppointmentRescheduleModal: undefined,
      ToggleExpert: undefined,
      fetchError: undefined,
      sort: false,
      sortByType: undefined,
      searchBy: undefined,
      searchData: "",
      sortPayemnt: false,
      sortPaymentByType: undefined,
      appointments: undefined,
      getJobFunctionsState: undefined,
      commentsAndRates: [],
      services: [],
      functions: [],
      industries: [],
      calendarDate: new Date(),
      expertAvailabilitites: undefined,
      expertAvailabilititesByDay: undefined,
      eventSelected: undefined,
      selectedRow: undefined,
      updateResponseStatus: undefined,
      outOfTime: false,
    };
  }

  componentDidMount() {
    let jwt = window.sessionStorage.getItem("jwt");
    if (this.props.experts) {
      this.setState({ experts: this.props.experts });
    } else {
      this.getExperts();
    }
    if (this.state.services.length <= 0) {
      getServices(jwt)
        .then((response) => {
          this.setState({ services: response });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (this.state.functions.length <= 0) {
      getJobFunctions(jwt)
        .then((response) => {
          this.setState({ functions: response });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (this.state.industries.length <= 0) {
      getIndustries(jwt)
        .then((response) => {
          this.setState({ industries: response });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getExpertAvailabilitiesDaysFunction = (expert_id, day_selected) => {
    this.props.loaderStatus(true);
    let jwtToken = sessionStorage.getItem("jwt");
    let interval = "d";
    const date = moment(day_selected).format();
    getExpertAvailabilitiesForAppointments(jwtToken, expert_id, interval, date)
      .then((response) => {
        response.forEach((element) => {
          element.start = new Date(moment(element.time).format());
          element.end = new Date(moment(element.time).format());
        });
        this.setState({ expertAvailabilititesByDay: response });
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.loaderStatus(false);
      });
  };

  renderEvents = () => {
    const { t, lng } = this.props;
    const {
      expertAvailabilitites,
      expertAvailabilititesByDay,
      calendarDate,
      eventSelected,
    } = this.state;
    moment.locale(lng);
    if (expertAvailabilitites) {
      return (
        <div className="row mt-5 w-100">
          {eventSelected && (
            <p className="message">
              <span className="label">{t("date")}</span>
              <span>{moment(eventSelected.start_date).format("LLLL")}</span>
            </p>
          )}
          <div className="d-flex justify-content-around align-items-center flex-wrap w-100 mt-3">
            <div className="display-bock">
              <Calendar
                key={Math.random()}
                localizer={localizer}
                defaultDate={calendarDate}
                defaultView="month"
                views={["month"]}
                events={expertAvailabilitites}
                selectable={true}
                onSelectSlot={() => this.setState({ notAvailable: true })}
                onSelectEvent={(event) => this.validateEvent(event)}
                onNavigate={(event) => this.monthChange(event)}
                style={{ minWidth: "300px", minHeight: "300px" }}
              />
            </div>
            <div>
              {expertAvailabilititesByDay &&
                this.renderExpertAvailabilitiesByDay()}
            </div>
          </div>
        </div>
      );
    }
  };

  monthChange = (event) => {
    const { updateAppointmentState } = this.state;
    // this.props.loaderStatus(true);
    let jwtToken = sessionStorage.getItem("jwt");
    let interval = "m";
    const expert_id = updateAppointmentState.expert["id"];
    const date = moment(event).startOf("month").format();
    getExpertAvailabilitiesForAppointments(jwtToken, expert_id, interval, date)
      .then((response) => {
        response.forEach((element) => {
          element.start = new Date(moment(element.date).format());
          element.end = new Date(moment(element.date).format());
        });
        this.setState({
          expertAvailabilitites: response,
          calendarDate: new Date(date),
        });
        // this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        // this.props.loaderStatus(false);
      });
  };

  validateEvent = (event) => {
    const { updateAppointmentState } = this.state;
    this.setState({ notAvailable: false });
    let date = moment(event.date);
    let today = moment(new Date());
    let daysDifference = today.diff(date, "days");
    let expert_id = updateAppointmentState.expert["id"];
    if (daysDifference > 0) {
      this.setState({ pastEvent: true, expertAvailabilititesByDay: undefined });
    } else {
      this.setState({ pastEvent: false, overMonthPackage: false });
      this.getExpertAvailabilitiesDaysFunction(expert_id, event.date);
    }
  };

  renderExpertAvailabilitiesByDay = () => {
    const { t } = this.props;
    let data, auxData;
    let allData = this.state.expertAvailabilititesByDay;
    if (allData.length > 2) {
      auxData = [...allData];
      auxData.pop();
      data = auxData;
    } else {
      data = [...allData];
    }
    let disableElement = false;
    if (data) {
      return (
        <div className={"expert_appointments_container"}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{t("Month")}</th>
                <th>{t("Day")}</th>
                <th>{t("Hour")}</th>
              </tr>
            </thead>
            <TablePagination
              itemsperpage={5}
              nocolumns={4}
              items={data}
              pagesspan={4}
              selectDateForAppointment={(id) =>
                this.selectDateForAppointment(id)
              }
              properties={this.props}
              disableElement={disableElement}
              selectedRow={this.state.selectedRow}
            />
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  selectDateForAppointment = (id) => {
    const { updateAppointmentState } = this.state;
    let duration;
    let data = this.state.expertAvailabilititesByDay;
    let services = this.state.services;
    this.setState({ selectedRow: id });
    let eventSelected = Object.assign(
      {},
      data.find((o) => o.id === parseInt(id))
    );
    if (services.length > 0) {
      let service = services.find(
        (o) => o.id === updateAppointmentState.service_id
      );
      duration = service.duration_in_min;
    }
    let endDate = moment(eventSelected.end).add(duration, "minutes");
    eventSelected.end = new Date(moment(endDate).format());
    let finalDate = data[data.length - 1];
    finalDate = moment(finalDate.end);
    let isValidDate =
      moment(endDate).isSameOrBefore(finalDate) &&
      moment(moment()).isSameOrBefore(endDate);
    if (isValidDate) {
      eventSelected.start_date = moment.utc(eventSelected.start).format();
      eventSelected.end_date = moment.utc(eventSelected.end).format();
      this.setState({ eventSelected, outOfTime: false });
    } else {
      this.setState({ eventSelected: undefined, outOfTime: true });
    }
  };

  updateEvent = () => {
    this.props.loaderStatus(true);
    const { updateAppointmentState, eventSelected } = this.state;
    let event = updateAppointmentState;
    event.type = "OpenAppointment";
    let jwtToken = sessionStorage.getItem("jwt");
    event.message = "Accepted";
    let newEventObject = {
      id: event.id,
      start_date: eventSelected.start_date,
      end_date: eventSelected.end_date,
      type: event.type,
      cancelled_on: "",
      cancelled_by: "",
      cancel_reason: "",
      price: event.price,
      search_history_id: event.search_history_id,
      service_request: event.service_request,
      service_id: event.service_id,
      expert_id: event.expert["id"],
      user_id: event.client["id"],
    };
    updateAppointment(jwtToken, newEventObject)
      .then((response) => {
        if (response.type === event.type) {
          this.setState({ updateResponseStatus: true });
        } else {
          this.setState({ updateResponseStatus: false });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.loaderStatus(false);
      });
  };

  cleanData = () => {
    this.setState({ expertBalance: undefined, ToggleExpert: undefined });
  };

  showToggleModal = async (userID) => {
    this.props.loaderStatus(true);
    let user = this.props.experts.find((o) => o.id === parseInt(userID));
    let jwt = window.sessionStorage.getItem("jwt");
    let ID = user.id;
    this.getAppointments(userID);
    await getExpertBalance(jwt, ID)
      .then((response) => {
        this.setState({ expertBalance: response });
        this.showModal(userID);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ expertBalance: undefined });
        this.props.loaderStatus(false);
      });
  };

  showModal = (userID) => {
    let user = this.props.experts.find((o) => o.id === parseInt(userID));
    this.setState({
      showToggleModal: true,
      ToggleExpert: user,
      fetchError: undefined,
    });
    this.props.loaderStatus(false);
  };

  closeToggleModal = (userID) => {
    this.setState({
      showToggleModal: false,
      ToggleExpert: undefined,
      fetchError: undefined,
    });
  };

  updateExpert = async (expert) => {
    this.props.loaderStatus(true);
    let jwt = window.sessionStorage.getItem("jwt");
    await toggleExpertStatus(jwt, expert, expert.id)
      .then((response) => {
        if (response.error) {
          this.setState({ fetchError: response.error });
        } else {
          this.setState({ fetchError: undefined });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ fetchError: error });
        this.props.loaderStatus(false);
      });
  };

  toggleAdmin = async (expert) => {
    this.props.loaderStatus(true);
    let jwt = window.sessionStorage.getItem("jwt");
    expert.admin === true ? (expert.admin = false) : (expert.admin = true);
    await toggleExpertStatus(jwt, expert, expert.id)
      .then((response) => {
        if (response.error) {
          this.setState({ fetchError: response.error });
        } else {
          this.setState({ fetchError: undefined });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ fetchError: error });
        this.props.loaderStatus(false);
      });
  };

  toogleExpertStatus = async (userID) => {
    this.props.loaderStatus(true);
    let user = this.props.experts.find((o) => o.id === parseInt(userID));
    let newData = { ...user };
    if (user.active === true) {
      newData.active = false;
      user.active = false;
    } else {
      newData.active = true;
      user.active = true;
    }
    let jwt = window.sessionStorage.getItem("jwt");
    await toggleExpertStatus(jwt, newData, newData.id)
      .then((response) => {
        if (response.error) {
          this.setState({ fetchError: response.error });
        } else {
          this.setState({ fetchError: undefined });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ fetchError: error });
        this.props.loaderStatus(false);
      });
  };

  getExperts = async () => {
    let jwt = window.sessionStorage.getItem("jwt");
    this.setState({ experts: undefined });
    await getAllExperts(jwt)
      .then((response) => {
        this.props.expertsInformation(response);
        this.setState({ experts: this.props.experts, searchBy: undefined });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAppointments = async (userID) => {
    let user = this.props.experts.find((o) => o.id === parseInt(userID));
    let jwt = window.sessionStorage.getItem("jwt");
    let ID = user.id;
    await getExpertAppointments(jwt, ID)
      .then((response) => {
        this.props.appointmentsInformation(response);
        this.setState({ appointments: this.props.appointmentsInfo });
      })
      .catch((error) => {
        console.log(error);
        // this.props.loaderStatus(false);
        this.setState({ appointments: undefined });
      });
  };

  sortByName = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
      : newData.sort((a, b) => (a.first_name < b.first_name ? 1 : -1));
    this.setState({ experts: newData, sortByType: "name" });
  };

  sortByRate = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) =>
          parseFloat(a.rates) > parseFloat(b.rates) ? 1 : -1
        )
      : newData.sort((a, b) =>
          parseFloat(a.rates) < parseFloat(b.rates) ? 1 : -1
        );
    this.setState({ experts: newData, sortByType: "rateTable" });
  };

  sortByCreated = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        )
      : newData.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        );
    this.setState({ experts: newData, sortByType: "created" });
  };

  sortByStatus = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) => (a.active > b.active ? 1 : -1))
      : newData.sort((a, b) => (a.active < b.active ? 1 : -1));
    this.setState({ experts: newData, sortByType: "status" });
  };

  changeItemStatus = (item) => {};

  sortByID = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) => (a.id > b.id ? 1 : -1))
      : newData.sort((a, b) => (a.id < b.id ? 1 : -1));
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "id" });
  };

  sortByPaidSatus = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) => (a.paid > b.paid ? 1 : -1))
      : newData.sort((a, b) => (a.paid < b.paid ? 1 : -1));
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "paidStatus" });
  };

  sortByBalance = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) => (a.balance_id > b.balance_id ? 1 : -1))
      : newData.sort((a, b) => (a.balance_id < b.balance_id ? 1 : -1));
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "balance" });
  };

  sortByFee = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) =>
          a.application_fees > b.application_fees ? 1 : -1
        )
      : newData.sort((a, b) =>
          a.application_fees < b.application_fees ? 1 : -1
        );
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "fee" });
  };

  sortByDate = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        )
      : newData.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        );
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "date" });
  };

  sortByAmount = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.expertBalance;
    let sortType = this.state.sortPayemnt;
    let newData = data.balance_items;
    sortType === true
      ? newData.sort((a, b) => (a.amount > b.amount ? 1 : -1))
      : newData.sort((a, b) => (a.amount < b.amount ? 1 : -1));
    data.balance_items = [...newData];
    this.setState({ expertBalance: data, sortPaymentByType: "amount" });
  };

  sortAppID = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) => (a.id > b.id ? 1 : -1))
      : newData.sort((a, b) => (a.id < b.id ? 1 : -1));
    this.setState({ appointments: data, sortPaymentByType: "appID" });
  };

  sortAppClient = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) =>
          a.client["first_name"] > b.client["first_name"] ? 1 : -1
        )
      : newData.sort((a, b) =>
          a.client["first_name"] < b.client["first_name"] ? 1 : -1
        );
    this.setState({ appointments: data, sortPaymentByType: "appClient" });
  };

  sortAppStatus = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) => (a.type > b.type ? 1 : -1))
      : newData.sort((a, b) => (a.type < b.type ? 1 : -1));
    this.setState({ appointments: data, sortPaymentByType: "appStatus" });
  };

  sortAppService = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) => (a.service_id > b.service_id ? 1 : -1))
      : newData.sort((a, b) => (a.service_id < b.service_id ? 1 : -1));
    this.setState({ appointments: data, sortPaymentByType: "appService" });
  };

  sortAppStart = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) =>
          new Date(a.start_date) > new Date(b.start_date) ? 1 : -1
        )
      : newData.sort((a, b) =>
          new Date(a.start_date) < new Date(b.start_date) ? 1 : -1
        );
    this.setState({ appointments: data, sortPaymentByType: "appStart" });
  };

  sortAppEnd = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) =>
          new Date(a.end_date) > new Date(b.end_date) ? 1 : -1
        )
      : newData.sort((a, b) =>
          new Date(a.end_date) < new Date(b.end_date) ? 1 : -1
        );
    this.setState({ appointments: data, sortPaymentByType: "appEnd" });
  };

  sortAppAmount = () => {
    this.setState((prevState) => ({
      sortPayemnt: !prevState.sortPayemnt,
    }));
    let data = this.state.appointments;
    let sortType = this.state.sortPayemnt;
    let newData = data;
    sortType === true
      ? newData.sort((a, b) => (a.price > b.price ? 1 : -1))
      : newData.sort((a, b) => (a.price < b.price ? 1 : -1));
    this.setState({ appointments: data, sortPaymentByType: "appAmount" });
  };

  sortCommID = (ID) => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortable = data.find((o) => o.id === ID);
    let sortType = this.state.sort;
    sortType === true
      ? sortable.comments.sort((a, b) => (a.id > b.id ? 1 : -1))
      : sortable.comments.sort((a, b) => (a.id < b.id ? 1 : -1));
    this.setState({ experts: data, sortByType: "commID" });
  };

  sortCommReviewer = (ID) => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortable = data.find((o) => o.id === ID);
    let sortType = this.state.sort;
    sortType === true
      ? sortable.comments.sort((a, b) =>
          a.reviewer_name > b.reviewer_name ? 1 : -1
        )
      : sortable.comments.sort((a, b) =>
          a.reviewer_name < b.reviewer_name ? 1 : -1
        );
    this.setState({ experts: data, sortByType: "commReviewer" });
  };

  sortCommRate = (ID) => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortable = data.find((o) => o.id === ID);
    let sortType = this.state.sort;
    sortType === true
      ? sortable.comments.sort((a, b) => (a.rate > b.rate ? 1 : -1))
      : sortable.comments.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    this.setState({ experts: data, sortByType: "commRate" });
  };

  sortCommReview = (ID) => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.experts;
    let sortable = data.find((o) => o.id === ID);
    let sortType = this.state.sort;
    sortType === true
      ? sortable.comments.sort((a, b) => (a.review > b.review ? 1 : -1))
      : sortable.comments.sort((a, b) => (a.review < b.review ? 1 : -1));
    this.setState({ experts: data, sortByType: "commReview" });
  };

  getUserResume = (userID) => {
    const { t } = this.props;
    let user = this.props.experts.find((o) => o.id === parseInt(userID));
    let url = user ? user["resume_url"] : undefined;
    if (url) {
      let final_url = url.split("expert/resume");
      return (
        <button
          className="btn uploadResume"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => {
            event.stopPropagation();
            let link = document.createElement("a");
            link.download = "Expert Resume";
            link.href = `https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/expert/resume${final_url[1]}`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link = undefined;
          }}
        >
          <i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp;
          {t("ETExpertResume")}
        </button>
      );
    } else {
      return null;
    }
  };

  updateAppointment = (id) => {
    const { appointments } = this.state;
    let appointment = appointments.find((o) => o.id === parseInt(id));
    this.setState({
      updateAppointmentState: appointment,
      showAppointmentRescheduleModal: true,
    });
  };

  renderUpdateAppointmentInformation = () => {
    let jwtToken = sessionStorage.getItem("jwt");
    const {
      updateAppointmentState,
      services,
      eventSelected,
      updateResponseStatus,
    } = this.state;
    const { client, expert, service_request } = updateAppointmentState;
    const { t } = this.props;
    let service = services.find(
      (o) => o.id === updateAppointmentState.service_id
    );
    let requestBody = [];
    let interval = "m";
    const expert_id = updateAppointmentState.expert["id"];
    const date = moment().startOf("month").format();

    if (this.state.expertAvailabilitites === undefined) {
      getExpertAvailabilitiesForAppointments(
        jwtToken,
        expert_id,
        interval,
        date
      )
        .then((response) => {
          response.forEach((element) => {
            element.start = new Date(moment(element.date).format());
            element.end = new Date(moment(element.date).format());
          });
          this.setState({ expertAvailabilitites: response });
          this.props.loaderStatus(false);
        })
        .catch((error) => {
          console.log(error);
          this.props.loaderStatus(false);
        });
    }
    Object.keys(service_request).map((item) => {
      let currentRequest = `
            <div class = "col-12 mb-3">
                <div class="card">
                <div class="card-body">
                    <h5>${t(item)}</h5>
                    <p>${service_request[item]}</p>
                </div>
                </div>
            </div >`;
      requestBody.push(currentRequest);
      return null;
    });
    return (
      <div className="container">
        <div className="w-100 d-flex flex-row justify-content-center align-items-center">
          <div className="userImage client">
            <img
              className="img-fluid rounded-circle userProfileImage"
              src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/client/avatar/${client.uuid}/0.jpeg`}
              alt="user"
            />
          </div>
          <div className="userImage expert">
            <img
              className="img-fluid rounded-circle userProfileImage"
              src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/expert/avatar/${expert.uuid}/0.jpeg`}
              alt="user"
            />
          </div>
        </div>
        <div className="text_container text-center my-5">
          <p className="header">{`${t("appService")}: ${service.type}`}</p>
          <p className="subheader">
            {`${t("expert")}: ${expert.first_name}  ${expert.last_name}`} <br />
            {`${t("client")}: ${client.first_name}  ${client.last_name}`}
          </p>
        </div>
        <div
          className="row align-items-start"
          dangerouslySetInnerHTML={{ __html: requestBody.join("") }}
        ></div>
        <div className="row align-items-start mt-3">
          <div className="col-12 col-lg-4 mx-auto card paymentInformation">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center header">
              <h5 className="text-white">{t("appointment_data")}</h5>
            </div>
            <p className="message">
              <span className="label">{t("ETStatus")}</span>
              <span>{updateAppointmentState.type}</span>
            </p>
            <p className="message">
              <span className="label">{t("appStart")}</span>
              <span>
                {moment(updateAppointmentState.start_date).format(
                  "DD MM YYYY hh:mm:ss A"
                )}
              </span>
            </p>
            <p className="message">
              <span className="label">{t("appEnd")}</span>
              <span>
                {moment(updateAppointmentState.end_date).format(
                  "DD MM YYYY hh:mm:ss A"
                )}
              </span>
            </p>
            <p className="message">
              <span className="label">{t("ETcreated")}</span>
              <span>
                {moment(updateAppointmentState.created_at).format(
                  "DD MM YYYY hh:mm:ss A"
                )}
              </span>
            </p>
            <p className="message">
              <span className="label">{t("updated_at")}</span>
              <span>
                {moment(updateAppointmentState.updated_at).format(
                  "DD MM YYYY hh:mm:ss A"
                )}
              </span>
            </p>
            {(updateAppointmentState.cancel_reason &&
              updateAppointmentState.cancel_reason.length) > 0 && (
              <p className="message">
                <span className="label">{t("cancel_reason")}</span>
                <span>{updateAppointmentState.cancel_reason}</span>
              </p>
            )}
            {(updateAppointmentState.cancelled_by &&
              updateAppointmentState.cancelled_by.length) > 0 && (
              <p className="message">
                <span className="label">{t("cancelled_by")}</span>
                <span>
                  {updateAppointmentState.cancelled_by === "systemUser"
                    ? t("systemUser")
                    : updateAppointmentState.cancelled_by ===
                      updateAppointmentState.client["id"]
                    ? t("SearchExpert")
                    : t("Expert")}
                </span>
              </p>
            )}
            {(updateAppointmentState.cancelled_on &&
              updateAppointmentState.cancelled_on.length) > 0 && (
              <p className="message">
                <span className="label">{t("cancelled_on")}</span>
                <span>
                  {moment(updateAppointmentState.cancelled_on).format(
                    "DD MM YYYY hh:mm:ss A"
                  )}
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="row">
          {this.state.notAvailable && (
            <div className="alert alert-danger" role="alert">
              {t("SA_L12")}
            </div>
          )}
          {this.state.pastEvent && (
            <div className="alert alert-warning" role="alert">
              {t("SA_L13")}
            </div>
          )}
          {this.state.outOfTime && (
            <div className="alert alert-warning" role="alert">
              {t("SA_L14")}
            </div>
          )}
          {this.state.expertAvailabilitites && this.renderEvents()}
        </div>
        {eventSelected && updateResponseStatus === undefined && (
          <div className="row">
            <div className="alert alert-warning mx-auto" role="alert">
              {t("updateWarning")}
            </div>
            <button onClick={() => this.updateEvent()} className="btn mb-2">
              {t("updateBtn")}
            </button>
          </div>
        )}
        {updateResponseStatus !== undefined ? (
          <div className="row mb-3">
            <div
              className={
                "mx-auto alert " +
                (updateResponseStatus === true
                  ? "alert-success"
                  : "alert-danger")
              }
              role="alert"
            >
              {updateResponseStatus === true
                ? t("updateSuccess")
                : t("updateError")}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  closeAppointmentRescheduleModal = () => {
    this.setState({
      updateAppointmentState: undefined,
      showAppointmentRescheduleModal: undefined,
    });
  };

  requestData = async () => {
    this.props.loaderStatus(true);
    const { searchData, searchBy } = this.state;
    let jwt = window.sessionStorage.getItem("jwt");
    await getAllUsersBySearch(jwt, searchBy, searchData)
      .then((response) => {
        this.props.expertsInformation(response);
        this.setState({ experts: response });
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.loaderStatus(false);
        alert("No information match the request");
      });
  };

  renderFilters = () => {
    const { t } = this.props;
    const { searchBy, searchData } = this.state;
    return (
      <div className="form-inline mb-5">
        <DownloadCsv experts={this.state.experts} />
        <div className="form-group mr-3 ml-5">
          <select
            className="form-control customPointer custom-select "
            name="searchType"
            id="searchType"
            value={this.state.searchBy}
            onChange={(e) => this.setState({ searchBy: e.target.value })}
          >
            <option value={undefined} default>
              {t("selectDefault")}
            </option>
            <option value="first_name">{t("first_name")}</option>
            <option value="last_name">{t("last_name")}</option>
            <option value="email">{t("email")}</option>
            <option value="active">{t("active")}</option>
          </select>
        </div>
        {searchBy !== "active" ? (
          <div className="form-group mr-3">
            <input
              onChange={(event) =>
                this.setState({ searchData: event.target.value })
              }
              type="text"
              className="form-control"
              name="degree_title"
              id="degree_title"
              defaultValue={searchData}
              placeholder={t("searchInput")}
              disabled={searchBy === undefined}
            />
          </div>
        ) : (
          <div className="form-group mr-3">
            <select
              className="form-control custom-select"
              name="statisSelect"
              id="statisSelect"
              value={this.state.searchData}
              onChange={(e) => this.setState({ searchData: e.target.value })}
            >
              <option value="" default>
                {t("selectDefault")}
              </option>
              <option value="true" default>
                {t("activeStatus")}
              </option>
              <option value="false">{t("disabled")}</option>
            </select>
          </div>
        )}
        <button
          onClick={() => this.requestData()}
          className="btn mb-2"
          disabled={searchBy === undefined}
        >
          {t("search")}
        </button>
        {searchBy && (
          <button
            onClick={() => this.getExperts()}
            type="button"
            className="re-btn btn-danger btn-round mb-2 ml-5"
            aria-label={t("clear")}
          >
            x
          </button>
        )}
      </div>
    );
  };

  renderTable = () => {
    let data = this.state.experts;
    const { t } = this.props;
    const { sort, sortByType } = this.state;
    if (data && data.length > 0) {
      return (
        <div className="expert_appointments_container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th onClick={() => this.sortByName()} className="customPointer">
                  {t("ETName")}&nbsp;&nbsp;
                  {sort && sortByType === "name" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "name" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th onClick={() => this.sortByRate()} className="customPointer">
                  {t("ETRate")}&nbsp;&nbsp;
                  {sort && sortByType === "rateTable" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "rateTable" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th>{t("ETResume")}</th>
                <th
                  onClick={() => this.sortByCreated()}
                  className="customPointer"
                >
                  {t("ETcreated")}&nbsp;&nbsp;
                  {sort && sortByType === "created" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "created" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th
                  onClick={() => this.sortByStatus()}
                  className="customPointer"
                >
                  {t("ETStatus")}&nbsp;&nbsp;
                  {sort && sortByType === "status" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "status" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
              </tr>
            </thead>
            <ExpertsTable
              itemsperpage={10}
              nocolumns={4}
              items={data}
              pagesspan={4}
              changeExpertStatus={(userID) => this.showToggleModal(userID)}
              getUserResume={(userID) => this.getUserResume(userID)}
              propertires={this.props}
            />
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let {
      showToggleModal,
      ToggleExpert,
      updateAppointmentState,
      showAppointmentRescheduleModal,
    } = this.state;
    return (
      <div>
        {this.renderFilters()}
        {this.renderTable()}
        {showToggleModal && ToggleExpert ? (
          <Modal
            isOpen={this.state.showToggleModal}
            onRequestClose={this.closeToggleModal}
            className="animated fadeIn fullWidthModal showExpertModal"
            overlayClassName="Overlay"
          >
            <button
              onClick={() => {
                this.closeToggleModal();
              }}
              type="button"
              className="closeButton"
              aria-label=""
              style={{ display: "inline-block" }}
            >
              <ReactSVG src={cancel} />
            </button>
            <ExpertModalProfile
              cleanData={() => this.cleanData}
              changeExpertStatus={(userID) => this.toogleExpertStatus(userID)}
              updateExpert={(expert) => this.updateExpert(expert)}
              toggleAdmin={(expert) => this.toggleAdmin(expert)}
              expert={ToggleExpert}
              currentState={this.state}
              t={this.props.t}
              functions={this.state.functions}
              industries={this.state.industries}
              changeItemStatus={(item) => this.changeItemStatus(item)}
              sortByID={this.sortByID}
              sortByPaidSatus={this.sortByPaidSatus}
              sortByBalance={this.sortByBalance}
              sortByFee={this.sortByFee}
              sortByDate={this.sortByDate}
              sortByAmount={this.sortByAmount}
              sortAppID={this.sortAppID}
              sortAppClient={this.sortAppClient}
              sortAppStatus={this.sortAppStatus}
              sortAppService={this.sortAppService}
              sortAppStart={this.sortAppStart}
              sortAppEnd={this.sortAppEnd}
              sortAppAmount={this.sortAppAmount}
              sortCommID={(ID) => this.sortCommID(ID)}
              sortCommReviewer={(ID) => this.sortCommReviewer(ID)}
              sortCommRate={(ID) => this.sortCommRate(ID)}
              sortCommReview={(ID) => this.sortCommReview(ID)}
              updateAppointment={(appointment_id) =>
                this.updateAppointment(appointment_id)
              }
            />
          </Modal>
        ) : null}
        {updateAppointmentState && showAppointmentRescheduleModal ? (
          <Modal
            isOpen={showAppointmentRescheduleModal}
            onRequestClose={this.closeAppointmentRescheduleModal}
            className="animated fadeIn showAppointmentModal"
            overlayClassName="Overlay"
          >
            <button
              onClick={() => {
                this.closeAppointmentRescheduleModal();
              }}
              type="button"
              className="closeButton"
              aria-label=""
              style={{ display: "inline-block" }}
            >
              <ReactSVG src={cancel} />
            </button>
            {this.renderUpdateAppointmentInformation()}
          </Modal>
        ) : null}
      </div>
    );
  }
}

Dashboard.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  loaderStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loaderStatus: (value) => dispatch(loader(value)),
  expertsInformation: (value) => dispatch(experts_information(value)),
  appointmentsInformation: (value) => dispatch(appointments_information(value)),
});

const mapStateToProps = ({ general }) => ({
  experts: general.experts_information,
  appointmentsInfo: general.appointments_information,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
