import React from 'react'
import ReactNextPaging from "react-next-paging";
// Externals
import ReactSVG from 'react-svg';
import moment from 'moment';
import 'moment/locale/es';
// Images
import star from './../../../assets/images/star.svg';

const ExpertsTable = ({ itemsperpage, nocolumns, items, pagesspan, changeExpertStatus, getUserResume, propertires, toogleUserStatus }) => {
  moment.locale(propertires.lng);
  const { t } = propertires;
  return (
    <ReactNextPaging
      itemsperpage={itemsperpage}
      nocolumns={nocolumns}
      items={items}
      pagesspan={pagesspan}
    >
      {({
        getBackButtonProps,
        getFastBackButtonProps,
        getFwdButtonProps,
        getFastFwdButtonProps,
        getSelPageButtonProps,
        nopages,
        inipagearray,
        pagesforarray,
        currentpage,
        noitems,
        initialitem,
        lastitem,
        goBackBdisabled,
        goFastBackBdisabled,
        goFwdBdisabled,
        goFastFwdBdisabled
      }) => (
          <tbody>
            {items.slice(initialitem, lastitem).map((item, index) => {
              return (
                <tr key={item.id} className="customPointer" onClick={() => changeExpertStatus(item.id)} >
                  <td><img style={{ width: '60px', heigth: '60px' }} className="img-fluid rounded-circle userProfileImage d-inline" src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/${(item.type).toLowerCase()}/avatar/${item.uuid}/0.jpeg`} alt="user" />&nbsp;&nbsp; {item.first_name} {item.last_name}</td>
                  <td>
                    {item.review_rate > 0 ?
                      <div className="rate">{parseFloat(item.review_rate).toFixed(1)}
                        <ReactSVG src={star} className="d-inline-block" />
                      </div>
                      :
                      <p>{t('no_data')}</p>
                    }
                  </td>
                  <td>
                    {item.resume_url ?
                      getUserResume(item.id)
                      :
                      <p>{t('expertNoResume')}</p>
                    }
                  </td>
                  <td>
                    {moment(item.created_at).format('DD-MMMM-YYYY')}
                  </td>
                  <td>
                    <label className="switch">
                      <input onChange={() => changeExpertStatus(item.id)} type="checkbox" checked={item.active} />
                      <span className="slider round"></span>
                    </label>
                  </td>
                </tr>
              );
            })}
            {noitems > 0
              ? [
                <tr key={"pagingrow" + 100}>
                  <td colSpan={nocolumns} style={{ textAlign: "center", paddingLeft: '0px' }}>
                    <button
                      className="buttonStyles"
                      {...getFastBackButtonProps()}
                      disabled={goFastBackBdisabled}
                    >
                      {"<<"}
                    </button>
                    <button
                      className="buttonStyles"
                      {...getBackButtonProps()}
                      disabled={goBackBdisabled}
                    >
                      {"<"}
                    </button>
                    {Array.from(
                      { length: pagesforarray },
                      (v, i) => i + inipagearray
                    ).map(page => {
                      return (
                        <button
                          key={page}
                          className="pageButton"
                          {...getSelPageButtonProps({ page: page })}
                          disabled={currentpage === page}
                        >
                          {page}
                        </button>
                      );
                    })}
                    <button
                      className="buttonStyles"
                      {...getFwdButtonProps()}
                      disabled={goFwdBdisabled}
                    >
                      {">"}
                    </button>
                    <button
                      className="buttonStyles"
                      {...getFastFwdButtonProps()}
                      disabled={goFastFwdBdisabled}
                    >
                      {">>"}
                    </button>
                  </td>
                </tr>
              ]
              : null}
          </tbody>
        )}
    </ReactNextPaging>
  );
};

export default ExpertsTable;