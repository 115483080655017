import React, { Component } from 'react';
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Actions
import { login, logout, login_type, user_valid } from '../../actions/login';
import { get_user_profile } from './../../actions/profile';
import { loader } from "./../../actions/general";
// Externals imports
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
// Services
import { logoutUser, userAdminProfile } from './../../services/index';
// Components import
import Header from './components/Header';
import Dashboard from './../dashboard/Dashboard';
import Clients from './../clients/Clients';
import Appointments from './../appointments/Appointments';
// import Footer from './components/Footer';
import UserSettings from './../userSettings/UserSettings';
// Images
import cancel from './../../assets/images/cancel.svg';
import logo_vertical from './../../assets/images/logo_vertical.svg';
import user from './../../assets/images/user.svg';
import house from './../../assets/images/house.svg';
import gear from './../../assets/images/gear.svg';

Modal.setAppElement('#root');

class AppFrame extends Component {

    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            terms_modal: false,
        }
    }

    componentDidMount() {
        let userData = sessionStorage.getItem('userData');
        let userAuth = sessionStorage.getItem('userAuth');
        this.validateUser(JSON.parse(userData), JSON.parse(userAuth));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            let userData = sessionStorage.getItem('userData');
            let userAuth = sessionStorage.getItem('userAuth');
            this.validateUser(JSON.parse(userData), JSON.parse(userAuth));
            window.scrollTo(0, 0);
        }
    }

    closeTermsModal = () => {
        this.setState({ terms_modal: false });
    }

    logOutUserFunction = async () => {
        let userAuth = sessionStorage.getItem('userAuth');
        this.props.loaderStatus(true);
        await logoutUser(userAuth)
            .then(response => {
                if (response) {
                    this.props.logOut();
                    sessionStorage.clear();
                    this.props.history.push('/');
                    window.location.reload();
                }
            })
            .catch((err) => {
                sessionStorage.clear();
                this.props.history.push('/');
                window.location.reload();
            });
    }

    showMenu = () => {
        let menu = document.getElementsByClassName('sidebar')[0];
        menu.classList.toggle('show');
    }

    validateUser = async (userData, userAuth) => {
        if (!userAuth) {
            this.logOutUserFunction();
        }
        else {
            this.props.loginType(userData);
            this.props.userValid(userAuth);
            this.props.loginAction(true);
            let jwt = window.sessionStorage.getItem('jwt');
            if (userData.user_type === 'Expert') {
                // let userId = this.props.userProfile.id;
                await userAdminProfile(jwt)
                    .then(response => {
                        this.props.getUserProfile(response);
                    });
            }
        }
    };

    showTerms = () => {
        this.setState({ terms_modal: true });
    }

    hideNotificationBar = () => {
        let notificationBarElement = document.getElementById('notificationBar');
        let notificacionOverlay = document.getElementById('notificationBarOverlay');
        let body = document.body;
        body.classList.remove('overflow-hidden');
        notificationBarElement.classList.remove('show');
        notificacionOverlay.classList.remove('show');
    }

    showNotifiacions = () => {
        this.setState({ notifications: true, inbox: false });
    }

    showInbox = () => {
        this.setState({ notifications: false, inbox: true });
    }

    render() {
        const { props } = this;
        const { match, t, userProfile, userData } = props;
        let userType = undefined;
        let name = "";
        if (userProfile) {
            name = userProfile.first_name + ' ' + userProfile.last_name;
            userType = userData.user_type;
        }
        return (
            <div className="AppFrame">
                {props &&
                    <div className="container-fluid dashboardContent">
                        <div className="row">
                            <div className=" col-12 d-flex flex-column sidebar pr-0">
                                <div className="userProfileInfo">
                                    { userType &&
                                        <img className="img-fluid rounded-circle userProfileImage" src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/${(userProfile.type).toLowerCase()}/avatar/${userProfile.uuid}/0.jpeg`} alt="user" />
                                    }
                                    <p>{t('menuWelcomeName', { name })}</p>
                                    <hr />
                                </div>
                                <div className="menuLinksContainer">
                                    <NavLink onClick={() => this.showMenu()} activeClassName='active' to={match.path + "/dashboard"}><ReactSVG className="svgIcon" src={house} /> {t('menuDashboard')}</NavLink>
                                    <NavLink onClick={() => this.showMenu()} activeClassName='active' to={match.path + "/clients"}><ReactSVG className="svgIcon" src={user} /> {t('menuClients')}</NavLink>
                                    <NavLink onClick={() => this.showMenu()} activeClassName='active' to={match.path + "/appointments"}><ReactSVG className="svgIcon" src={user} /> {t('menuAppointments')}</NavLink>
                                    <NavLink onClick={() => this.showMenu()} activeClassName='active' to={match.path + "/settings"}><ReactSVG className="svgIcon" src={gear} /> {t('menuSettings')}</NavLink>
                                </div>
                                <div className="menuBottom">
                                    <ReactSVG className="my-4 mx-auto text-center d-none d-md-block" src={logo_vertical} />
                                    {/* <p className="customPointer" onClick={() => this.showTerms()}>{t('Terms')}</p> */}
                                </div>
                            </div>
                            <div className=" col-12 col-md-9 dashboardMain mb-5">
                                <Header
                                    {...props}
                                    changeLanguage={props.changeLanguage}
                                    logOutUserFunction={this.logOutUserFunction}
                                    showMenu={this.showMenu}
                                />
                                <div className="content col-12">
                                    <Switch>
                                        <Route exact path={match.path + "/dashboard"} render={() =>
                                            <Dashboard
                                                {...props}
                                            />
                                        }
                                        />
                                        <Route exact path={match.path + "/clients"} render={() =>
                                            <Clients
                                                {...props}
                                            />
                                        }
                                        />
                                        <Route exact path={match.path + "/appointments"} render={() =>
                                            <Appointments
                                                {...props}
                                            />
                                        }
                                        />
                                        <Route exact path={match.path + "/settings"} render={() => 
                                            <UserSettings
                                                {...props}
                                                logOutUserFunction={this.logOutUserFunction}
                                            />
                                        }
                                        />
                                        <Route component={() => "404 not found"} />
                                    </Switch>
                                </div>
                                {/* <Footer /> */}
                            </div>
                        </div>
                        <div className="" id="notificationBarOverlay" onClick={() => this.hideNotificationBar()}> </div>
                        <div id="notificationBar" className="notificationBar">
                            <div className="notificationHeader">
                                <p>
                                    <span onClick={() => this.showNotifiacions()} className="customPointer">{t('notificationBar')}</span>|
                                    <span onClick={() => this.showInbox()} className="customPointer">{t('messagesBar')}</span>
                                </p>
                            </div>
                            <div className="notificationBarContent">
                                {this.state.notifications && (this.state.notifications ? 'Notificaciones' : 'No hay notificaciones')}
                                {this.state.inbox && (this.state.inbox ? 'Mensajes' : 'No hay mensajes')}
                            </div>
                        </div>
                        {
                            this.state.terms_modal &&

                            <Modal
                                isOpen={this.state.terms_modal}
                                onRequestClose={this.closeTermsModal}
                                className="animated fadeIn profileSubmitedModal"
                                overlayClassName="Overlay"
                            >
                                <button onClick={() => { this.closeTermsModal() }} type="button" className="closeButton" aria-label="" style={{ display: "inline-block" }}><ReactSVG src={cancel} /></button>
                                <div className="">
                                    <h3>{t('Terms')}</h3>
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <a className="nav-item nav-link active" id="nav-experts-tab" data-toggle="tab" href="#nav-experts" role="tab" aria-controls="nav-experts" aria-selected="true">{t('Expert')}</a>
                                            <a className="nav-item nav-link" id="nav-clients -tab" data-toggle="tab" href="#nav-clients " role="tab" aria-controls="nav-clients " aria-selected="false">{t('SearchExpert')}</a>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-experts" role="tabpanel" aria-labelledby="nav-experts-tab">
                                            <div dangerouslySetInnerHTML={{ __html: t('ExpertTermsText') }}></div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-clients " role="tabpanel" aria-labelledby="nav-clients -tab">
                                            <div dangerouslySetInnerHTML={{ __html: t('ClientTermsText') }}></div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
                }
            </div>
        );
    }
}

AppFrame.propTypes = {
    loginAction: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    loginType: PropTypes.func.isRequired,
    userValid: PropTypes.func.isRequired,
    loaderStatus: PropTypes.func.isRequired,
    getUserProfile: PropTypes.func.isRequired,
};


const mapDispatchToProps = dispatch => ({
    loginAction: value => dispatch(login(value)),
    logOut: () => dispatch(logout()),
    loginType: value => dispatch(login_type(value)),
    userValid: user => dispatch(user_valid(user)),
    getUserProfile: data => dispatch(get_user_profile(data)),
    loaderStatus: value => dispatch(loader(value)),
});

const mapStateToProps = ({ login, profile }) => ({
    userLogged: login.login,
    userData: login.login_type,
    userAuth: login.user_auth,
    userProfile: profile.user_profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppFrame));