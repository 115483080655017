import React from "react";
import moment from "moment";

export const DownloadCsv = ({ experts }) => {
  // Convert and download CSV
  const convertToCSV = (objArray) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };

  // Call this!!!
  const exportCSVFile = () => {
    let itemsFormatted = [];
    let fileTitle = `experts-${moment().format("MMMM-DD-YYYY")}`;
    let headers = {
      id: "Expert id",
      created_at: "Created",
      updated_at: "Updated",
      is_active: "Is expert active?",
      F_name: "First name",
      L_name: "Last name",
      email: "Email",
      contact_infor: "Contact information",
      billing_info: "Billing",
      languages: "Languages,",
    };
    // format the data
    // .replace(/,/g, '')
    experts.forEach((item) => {
      const billing = { ...item.billing_info };
      delete billing.id;
      delete billing.created_at;
      delete billing.updated_at;
      delete billing.user_id;
      const billing_raw = JSON.stringify(billing);
      const billing_converted = billing_raw.replace(/,/g, " ");
      const languajes_raw = item.languages_skill.languages;
      const converted_languages = JSON.stringify(languajes_raw).replace(
        /,/g,
        " "
      );
      const contact = {...item.contact_information};
      delete contact.created_at;
      delete contact.id;
      delete contact.updated_at;
      delete contact.user_id;
      const contact_converted = JSON.stringify(contact).replace(/,/g, " ");
      itemsFormatted.push({
        id: item.id,
        created_at: moment(item.created_at).format("MMMM-DD-YYYY"),
        updated_at: moment(item.updated_at).format("MMMM-DD-YYYY"),
        is_active: item.active ? 'Yes' : 'No',
        F_name: item.first_name,
        L_name: item.last_name,
        email: item.email,
        contact_infor: contact_converted,
        billing_info: billing_converted,
        languages: converted_languages
      });
    });

    if (headers && itemsFormatted) {
      itemsFormatted.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(itemsFormatted);

    let csv = convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + ".csv" || "export.csv";

    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  // Convert and download CSV

  return (
    <button className="icon-button btn-round" onClick={() => exportCSVFile()}>
      <i className="fa fa-download" aria-hidden="true"></i>
    </button>
  );
};
