import React, { Component } from 'react';
import { getExpertAvailabilitiesForAppointments } from './../../../services/index';
// Externals
import ReactSVG from 'react-svg';
import moment from 'moment';
import 'moment/locale/es';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
// Components
import TablePagination from './TablePagination';
// Images
import express from "./../../../assets/images/express.svg";
import mentoring from "./../../../assets/images/mentoring.svg";
import coaching from "./../../../assets/images/coaching.svg";
import user from "./../../../assets/images/user.svg";
import phone from "./../../../assets/images/phone.svg";
import letter from "./../../../assets/images/letter.svg";
import pin from "./../../../assets/images/pin.svg";
import caseIcon from "./../../../assets/images/caseIcon.svg";
import clock from "./../../../assets/images/clock.svg";

const localizer = momentLocalizer(moment);

class ExpertModalProfile extends Component {

  constructor() {
    super();
    this.state = {
      sort: false,
      sortPaymentByType: false,
      expertAvailabilitites: undefined,
    }
  }

  componentWillUnmount() {
    this.props.cleanData();
  }

  renderEducation = () => {
    const { t } = this.props;
    if (this.props.expert && this.props.expert['education_histories'].length > 0) {
      let history = this.props.expert['education_histories'];
      return (
        <div className="row align-items-start">
          {
            Object.entries(history).map((element) => {
              let from = new Date(element[1].date_start);
              let fromYear = from.getFullYear();
              let toDate = element[1].date_end !== 'Present' ? new Date(element[1].date_end) : element[1].date_end;
              let toYear = element[1].date_end !== 'Present' ? toDate.getFullYear() : toDate;
              return (
                <div className="col-12 col-md-4 infoCard mb-2" key={element[0]}>
                  <div className="card">
                    <div className="card-body text-left">
                      <h2>{element[1].studies}</h2>
                      <h3>{element[1].degree_title}</h3>
                      <p className="company">{element[1].institution_name}</p>
                      <p className="timeLapse">{`${fromYear} - ${toYear}`}</p>
                    </div>
                  </div>
                </div >
              );
            })
          }
        </div>
      );
    }
    else {
      return (
        <div>
          <p>{t('EMP_L1')}</p>
        </div>
      );
    }
  }

  renderLenguages = () => {
    const { t } = this.props;
    if (this.props.expert && this.props.expert.languages_skill) {
      let languages = this.props.expert.languages_skill;
      let languageCard = [];
      if (languages.constructor === Object) {
        Object.entries(languages.languages).map((level) => {
          let languageArray = [];
          let header = `${level[0]}Header`;
          level[1].forEach(element => {
            let language = `<p class="body-description">${element}</p>`;
            languageArray.push(language)
          });
          let currentCard = `
          <div class = "col-12 col-md-4">
          <h3>${t(header)}</h3>
            <div class="card">
              <div class="card-body">
                ${languageArray.join('')}
              </div>
            </div>
          </div >`;
          languageCard.push(currentCard);
          return null;
        });
      }
      return <div className="row align-items-start" dangerouslySetInnerHTML={{ __html: languageCard.sort().join('') }}></div>;
    }
    else {
      return (
        <div>
          <p>{t('EMP_L2')}</p>
        </div>
      );
    }
  }

  renderWorkExperience = () => {
    const { t } = this.props;
    if (this.props.expert && this.props.expert.work_histories.length > 0) {
      let industries = this.props.expert.work_histories;
      return (
        <div className="row align-items-start">
          {
            Object.entries(industries).map((industrie) => {
              let from = new Date(industrie[1].from);
              let fromYear = from.getFullYear();
              let toDate = industrie[1].to !== 'present' ? new Date(industrie[1].to) : industrie[1].to;
              let toYear = industrie[1].to !== 'present' ? toDate.getFullYear() : toDate;
              return (
                <div className="col-12 col-md-4 industrieCard" key={industrie[0]}>
                  <div className="card">
                    <div className="card-body">
                      <h3>{industrie[1].job_title}</h3>
                      <h5>{industrie[1].company}</h5>
                      <p className="company">{industrie[1].location}</p>
                      <p className="timeLapse">{fromYear} - {toYear}</p>
                      <p className="field">{industrie[1].industry_field}</p>
                      <p className="website"><a href={`https://${industrie[1].company_website}`} rel="noopener noreferrer" target="_blank">{industrie[1].company_website}</a></p>
                    </div>
                  </div>
                </div >
              )
            })
          }
        </div>
      );
    }
    else {
      return (
        <div>
          <p>{t('EMP_L3')}</p>
        </div>
      );
    }
  }

  renderHighLights = () => {
    const { t } = this.props;
    if (this.props.expert && this.props.expert.career_highlights.length > 0) {
      let careers = this.props.expert.career_highlights;
      return (
        careers.map((element, index) => {
          return (
            <div className="col-12 col-md-6" key={element.date_from}>
              <div className="card careerHighlights" >
                <div className="card-body">
                  <h2>{element.career_header}</h2>
                  <p className="body-description">{element.career_description}</p>
                </div>
              </div>
            </div>
          );
        })
      );
    }
    else {
      return (
        <div>
          <p>{t('EMP_L4')}</p>
        </div>
      );
    }
  }

  renderBalance = () => {
    const { currentState, t } = this.props;
    const { expertBalance, sortPayemnt, sortPaymentByType } = currentState;
    return (
      <div className="container-fluid">
        <p className="h6 mb-5"> Total amount: ${expertBalance.total_amount}</p>
        <div className="row justify-content-around">
          <table className="table table-hover">
            <thead>
              <tr>
                <th onClick={() => this.props.sortByID()} className="customPointer">
                  {t('appointment_id')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'id') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'id') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByPaidSatus()} className="customPointer">
                  {t('paid')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'paidStatus') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'paidStatus') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByBalance()} className="customPointer">
                  {t('balance_id')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'balance') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'balance') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByFee()} className="customPointer">
                  {t('application_fees')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'fee') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'fee') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByDate()} className="customPointer">
                  {t('created_at')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'date') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'date') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByAmount()} className="customPointer">
                  {t('ammount')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'amount') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'amount') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortByPaidSatus()} className="customPointer">
                  {t('active')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'paidStatus') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'paidStatus') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {
                (expertBalance.balance_items && expertBalance.balance_items.length > 0) ?
                  expertBalance.balance_items.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{item.appointment_id}</td>
                        <td>{item.paid ? 'yes' : 'no'}</td>
                        <td>{item.balance_id}</td>
                        <td>{item.application_fees}</td>
                        <td>{moment(item.created_at).format('MMMM DD YYYY hh:mm A')}</td>
                        <td>${item.amount}</td>
                        <td>
                          <label className="switch">
                            <input onChange={() => this.props.changeItemStatus(item)} type="checkbox" checked={item.paid} />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td><p>{t('no_data')}</p></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderAppointments = () => {
    const { currentState, t } = this.props;
    const { appointments, sortPayemnt, sortPaymentByType, services } = currentState;
    return (
      <div className="container-fluid">
        <p className="h6 mb-5"> Total appointments: {appointments.length}</p>
        <div style={{ overflowX: 'auto' }}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th onClick={() => this.props.sortAppID()} className="customPointer">
                  {t('appID')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appID') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appID') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppClient()} className="customPointer">
                  {t('appClient')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appClient') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appClient') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppStatus()} className="customPointer">
                  {t('appStatus')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appStatus') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appStatus') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppService()} className="customPointer">
                  {t('appService')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appService') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appService') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppStart()} className="customPointer">
                  {t('appStart')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appStart') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appStart') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppEnd()} className="customPointer">
                  {t('appEnd')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appEnd') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appEnd') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortAppAmount()} className="customPointer">
                  {t('appAmount')}&nbsp;&nbsp;
                    {
                    (sortPayemnt && sortPaymentByType === 'appAmount') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sortPayemnt && sortPaymentByType === 'appAmount') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {
                (appointments && appointments.length > 0 && services.length > 0) ?
                  appointments.map(item => {
                    let service = services.find(o => o.id === parseInt(item.service_id));
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.client['first_name']} {item.client['last_name']}</td>
                        <td>{item.type}</td>
                        <td>{service && service.type ? service.type : null}</td>
                        <td>{moment(item.start_date).format('MMMM DD YYYY hh:mm A')}</td>
                        <td>{moment(item.end_date).format('MMMM DD YYYY hh:mm A')}</td>
                        <td>${item.price}</td>
                        <td>
                          <button className="icon-button btn-round">
                            <i className="fa fa-calendar" aria-hidden="true" onClick={() => this.props.updateAppointment(item.id)}></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td><p>{t('no_data')}</p></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderComments = () => {
    const { currentState, t, expert } = this.props;
    const { sort, sortByType } = currentState;
    const { comments } = expert;
    return (
      <div className="container-fluid">
        <p className="h6 mb-5"> Total comments: {comments.length}</p>
        <div style={{ overflowX: 'auto' }}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th onClick={() => this.props.sortCommID(expert.id)} className="customPointer">
                  {t('commID')}&nbsp;&nbsp;
                    {
                    (sort && sortByType === 'commID') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sort && sortByType === 'commID') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortCommReviewer(expert.id)} className="customPointer">
                  {t('commReviewer')}&nbsp;&nbsp;
                    {
                    (sort && sortByType === 'commReviewer') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sort && sortByType === 'commReviewer') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortCommRate(expert.id)} className="customPointer">
                  {t('commRate')}&nbsp;&nbsp;
                    {
                    (sort && sortByType === 'commRate') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sort && sortByType === 'commRate') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
                <th onClick={() => this.props.sortCommReview(expert.id)} className="customPointer">
                  {t('commReview')}&nbsp;&nbsp;
                    {
                    (sort && sortByType === 'commReview') ?
                      <i className="fa fa-sort-desc" aria-hidden="true"></i> :
                      (!sort && sortByType === 'commReview') ?
                        <i className="fa fa-sort-asc" aria-hidden="true"></i> :
                        <i className="fa fa-sort" aria-hidden="true"></i>
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {
                (comments && comments.length > 0) ?
                  comments.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.reviewer_name}</td>
                        <td>{item.rate}</td>
                        <td><p className="text-left" style={{ maxWidth: '300px', whiteSpace: 'normal', margin: 'auto' }}>{item.review}</p></td>
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td><p>{t('no_data')}</p></td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  toggleService = (data) => {
    const { updateExpert, expert } = this.props;
    const { services } = expert;
    if (services.some(e => e === data)) {
      const index = services.indexOf(data);
      if (index > -1) {
        services.splice(index, 1);
      }
    }
    else {
      services.push(data);
    }
    updateExpert(expert);
  }

  renderServices = () => {
    const { expert } = this.props;
    const { services } = expert;
    const { t } = this.props;
    return (
      <div className="stepComponent row m-0 w-100">
        <div className={"servicesContainer card col-12 col-md-4" + (services.some(e => e === 'expressCheck') ? ' ' : ' isDisabled ')} onClick={() => this.toggleService('expressCheck')}>
          <div className="innerCard">
            <div className={"serviceStatus" + (services.some(e => e === 'expressCheck') ? ' serviceActive ' : ' ')}></div>
            <ReactSVG
              className="svgService"
              src={express}
            />
          </div>
          <h4>{t('express_service')}</h4>
        </div>
        <div className={"servicesContainer card col-12 col-md-4" + (services.some(e => e === 'mentoringCheck') ? ' ' : ' isDisabled ')} onClick={() => this.toggleService('mentoringCheck')}>
          <div className="innerCard">
            <div className={"serviceStatus" + (services.some(e => e === 'mentoringCheck') ? ' serviceActive ' : ' ')}></div>
            <ReactSVG
              className="svgService"
              src={mentoring}
            />
          </div>
          <h4>{t('mentoring_service')}</h4>
        </div>
        <div className={"servicesContainer card col-12 col-md-4" + (services.some(e => e === 'coachingCheck') ? ' ' : ' isDisabled ')} onClick={() => this.toggleService('coachingCheck')}>
          <div className="innerCard">
            <div className={"serviceStatus" + (services.some(e => e === 'coachingCheck') ? ' serviceActive ' : ' ')}></div>
            <ReactSVG
              className="svgService"
              src={coaching}
            />
          </div>
          <h4>{t('coaching_service')}</h4>
        </div>
      </div>
    );
  }

  renderContact = () => {
    const { expert, t } = this.props;
    const { contact_information } = expert;
    if (contact_information) {
      return (
        <div className="stepComponent contact-information row m-0 w-100 pt-3">
          <div className="row inputsContainer">
            <div id="first_name_container" className="form-group col-12 col-md-4">
              <label htmlFor="first_name">
                <ReactSVG className="svgIcon" src={user} />
              </label>
              <input
                type="text"
                className="form-control"
                name="first_name"
                id="first_name"
                data-tip="User Name"
                data-for="User Name"
                placeholder={t("ciUsername")}
                onChange={e => this.updateUserContact(e)}
                defaultValue={
                  contact_information["first_name"]
                }
                readOnly
              />
            </div>
            <div id="email_container" className="form-group col-12 col-md-4">
              <label htmlFor="email">
                <ReactSVG className="svgIcon" src={letter} />
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                id="email"
                placeholder={t("ciUserEmail")}
                onChange={e => this.updateUserContact(e)}
                defaultValue={contact_information["email"]}
                readOnly
              />
            </div>
            <div id="mobile_phone_container" className="form-group col-12 col-md-4">
              <label htmlFor="mobile_phone">
                <ReactSVG className="svgIcon" src={phone} />
              </label>
              <input
                className="form-control"
                placeholder={t("ciMobilePhone")}
                defaultValue={contact_information["mobile_phone"]}
                readOnly
              />
            </div>
            <div id="last_name_container" className="form-group col-12 col-md-4">
              <label htmlFor="last_name">
                <ReactSVG className="svgIcon" src={user} />
              </label>
              <input
                type="text"
                className="form-control"
                name="last_name"
                id="last_name"
                placeholder={t("ciUserLast")}
                onChange={e => this.updateUserContact(e)}
                defaultValue={
                  contact_information["last_name"]
                }
                readOnly
              />
            </div>
            <div id="location_container" className="form-group col-12 col-md-4">
              <label htmlFor="location">
                <ReactSVG className="svgIcon" src={pin} />
              </label>
              <input
                className="form-control"
                defaultValue={contact_information["location"]}
                readOnly
              />
            </div>
            <div id="office_phone_container" className="form-group col-12 col-md-4">
              <label htmlFor="office_phone">
                <ReactSVG className="svgIcon" src={caseIcon} />
              </label>
              <input
                className="form-control"
                defaultValue={contact_information["office_phone"]}
                readOnly
              />
            </div>
            <div id="middle_name_container" className="form-group col-12 col-md-4">
              <label htmlFor="middle_name">
                <ReactSVG className="svgIcon" src={user} />
              </label>
              <p className={"floatLabel" +
                (contact_information["middle_name"] && contact_information["middle_name"].length > 0 ? ' float ' : '')}
              >{t("ciFullName")}</p>
              <input
                type="text"
                className="form-control"
                name="middle_name"
                id="middle_name"
                placeholder={t("ciFullName")}
                onChange={e => this.updateUserContact(e)}
                defaultValue={
                  contact_information["middle_name"]
                }
                readOnly
              />
            </div>
            <div id="time_zone_container" className="form-group col-12 col-md-4">
              <label htmlFor="time_zone">
                <ReactSVG className="svgIcon" src={clock} />
              </label>
              <input
                type="text"
                className="form-control"
                name="time_zone"
                id="time_zone"
                placeholder={t("ciTimeZone")}
                defaultValue={contact_information["time_zone"]}
                readOnly
                disabled
              />
            </div>
            <div id="time_contacte_container" className="form-group col-12 col-md-4">
              <label htmlFor="time_contact">
                <ReactSVG className="svgIcon" src={clock} />
              </label>
              <input
                className="form-control"
                name="time_contact"
                id="time_contact"
                placeholder={t("ciTimeContact")}
                defaultValue={contact_information["time_contact"]}
                readOnly
              />
            </div>
          </div>
        </div >
      );
    }
    else
      return (
        <p className="row"></p>
      );
  }

  renderSkill = () => {
    let body = [];
    const { expert, industries, functions } = this.props;
    let current = expert.skills;
    let industry = industries;
    if (current && current.length > 0) {
      if (industry && functions) {
        return current.map((element, index) => {
          body = [];
          let industryName = industry.find(
            o => o.id === parseInt(element.industry)
          );
          element.function.forEach(userFunction => {
            let currentbody = "";
            let object = functions.find(
              o => o.id === parseInt(userFunction.job_functions)
            );
            let skillsArray = [];
            userFunction.skills.forEach(skill => {
              let childSkill = object.skills.find(
                o => o.id === parseInt(skill)
              );
              console.log(childSkill)
              if (childSkill && childSkill.name) skillsArray.push(childSkill.name);
            });
            currentbody = `<p class="body-description">${object.name}</p>
            <span>${skillsArray.join("<br/>")}</span>
            `;
            body.push(currentbody);
          });
          return (
            <div className="col-12 col-md-4" key={index}>
              <div className="card">
                <div className="card-body skillsCard">
                  <h2>{industryName.name}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: body.join("") }}
                  ></div>
                </div>
              </div>
            </div>
          );
        });
      }
    }
    return (
      <div className="col-12">
      </div>
    );
  };

  renderUpdateAppointmentInformation = () => {
    let jwtToken = sessionStorage.getItem('jwt');
    const { ToggleExpert } = this.props.currentState;
    let interval = 'm';
    const expert_id = ToggleExpert.id;
    const date = moment().startOf('month').format();

    if (this.state.expertAvailabilitites === undefined) {
      getExpertAvailabilitiesForAppointments(jwtToken, expert_id, interval, date)
        .then(response => {
          response.forEach(element => {
            element.start = new Date(moment(element.date).format());
            element.end = new Date(moment(element.date).format());
          });
          this.setState({ expertAvailabilitites: response });
        })
        .catch((error => {
          console.log(error);
        }));
    }
  }

  renderEvents = () => {
    const { t, lng } = this.props;
    const { expertAvailabilitites, expertAvailabilititesByDay, calendarDate, eventSelected } = this.state;
    moment.locale(lng);
    if (expertAvailabilitites) {
      return (
        <div className="row mt-5 w-100">
          {
            eventSelected &&
            <p className="message">
              <span className="label">{t('date')}</span>
              <span>{moment(eventSelected.start_date).format('LLLL')}</span>
            </p>
          }
          <div className="d-flex justify-content-around align-items-center flex-wrap w-100 mt-3">
            <div className="display-bock">
              <Calendar
                key={Math.random()}
                localizer={localizer}
                defaultDate={calendarDate}
                defaultView="month"
                views={['month']}
                events={expertAvailabilitites}
                selectable={true}
                onSelectSlot={() => this.setState({ notAvailable: true })}
                onSelectEvent={(event) => this.validateEvent(event)}
                onNavigate={(event) => this.monthChange(event)}
                style={{ minWidth: "300px", minHeight: "300px" }}
              />
            </div>
          {
            this.state.pastEvent &&
            <div className="alert alert-warning" role="alert">
              {t('SA_L13')}
            </div>
          }
            <div>
              {
                expertAvailabilititesByDay &&
                this.renderExpertAvailabilitiesByDay()
              }
            </div>
          </div>
        </div>
      );
    }
  }

  renderExpertAvailabilitiesByDay = () => {
    const { t } = this.props;
    let data, auxData;
    let allData = this.state.expertAvailabilititesByDay;
    if (allData.length > 2) {
      auxData = [...allData];
      auxData.pop();
      data = auxData;
    } else {
      data = [...allData];
    }
    let disableElement = false;
    if (data) {
      return (
        <div className={"expert_appointments_container"}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{t('Month')}</th>
                <th>{t('Day')}</th>
                <th>{t('Hour')}</th>
              </tr>
            </thead>
            <TablePagination
              itemsperpage={5}
              nocolumns={4}
              items={data}
              pagesspan={4}
              selectDateForAppointment={() => { }}
              properties={this.props}
              disableElement={disableElement}
              selectedRow={this.state.selectedRow}
            />
          </table>
        </div>
      );
    }
    else {
      return null;
    }
  }

  monthChange = (event) => {
    const { expert } = this.props;
    let jwtToken = sessionStorage.getItem('jwt');
    let interval = 'm';
    const expert_id = expert.id;
    const date = moment(event).startOf('month').format();
    getExpertAvailabilitiesForAppointments(jwtToken, expert_id, interval, date)
      .then(response => {
        response.forEach(element => {
          element.start = new Date(moment(element.date).format());
          element.end = new Date(moment(element.date).format());
        });
        this.setState({ expertAvailabilitites: response, calendarDate: new Date(date) });
      })
      .catch((error => {
        console.log(error);
      }));
  }

  validateEvent = event => {
    const { ToggleExpert } = this.props.currentState;
    this.setState({ notAvailable: false });
    let date = moment(event.date);
    let today = moment(new Date());
    let daysDifference = today.diff(date, 'days');
    let expert_id = ToggleExpert.id;
    if (daysDifference > 0) {
      this.setState({ pastEvent: true, expertAvailabilititesByDay: undefined });
    }
    else {
      this.setState({ pastEvent: false, overMonthPackage: false });
      this.getExpertAvailabilitiesDaysFunction(expert_id, event.date);
    }
  }

  getExpertAvailabilitiesDaysFunction = (expert_id, day_selected) => {
    let jwtToken = sessionStorage.getItem('jwt');
    let interval = 'd';
    const date = moment(day_selected).format();
    getExpertAvailabilitiesForAppointments(jwtToken, expert_id, interval, date)
      .then(response => {
        response.forEach(element => {
          element.start = new Date(moment(element.time).format());
          element.end = new Date(moment(element.time).format());
        });
        this.setState({ expertAvailabilititesByDay: response });
      })
      .catch((error => {
        console.log(error);
      }));
  }

  render() {
    const { t, changeExpertStatus, currentState, expert, toggleAdmin } = this.props;
    const { fetchError, expertBalance, appointments } = currentState;
    let userProfile = expert;
    let { first_name, last_name, comments, admin } = userProfile;
    let full_name = first_name + ' ' + last_name;
    return (
      <div className="ExpertsResultsContainer mt-5">
        <div className="profileBanner">
          <div onClick={() => toggleAdmin(expert)} className={"adminIconContainer" + (admin === true ? ' activeAdmin ' : '')}>
            <i className="fa fa-shield" aria-hidden="true"></i>
          </div>
          <img className="img-fluid rounded-circle userProfileImage" src={`https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/${(userProfile.type).toLowerCase()}/avatar/${userProfile.uuid}/${userProfile.type === 'Expert' ? '0.jpeg' : 'profilepic.jpeg'}`} alt="user" />
        </div>
        <div className="card">
          <div className="section-title text-center card-body">
            <h2 className="px-3">{t('EMP_L5', { full_name })} </h2>
            <p className="h4">{userProfile.about['title']}</p>
            <p>{t('ETEmail')}: <a href={`mailto:${userProfile.email}`}>{userProfile.email}</a></p>
            <div className="row my-3 justify-content-center align-items-center">
              <p className="m-0">{t('ETStatus')} &nbsp;</p>
              <label className="switch">
                <input onChange={() => changeExpertStatus(userProfile.id)} type="checkbox" checked={userProfile.active} />
                <span className="slider round"></span>
              </label>
            </div>
            {
              fetchError &&
              <div className="alert alert-danger" role="alert">
                {t('fetchError', { fetchError })}
              </div>
            }
            <p className="about">{userProfile.about['aboutBody']}</p>
            {currentState['expertAvailabilities'] !== undefined &&
              <div className="row">
                {t('EMP_L7')}
              </div>
            }
            <div className="row justify-content-center align-items-center">
              <p className="m-0 mt-3 h4">{t('ETContactInfo')} &nbsp;</p>
              {this.renderContact()}
            </div>
            <div className="row justify-content-center align-items-center">
              <p className="m-0 mt-3 h4">{t('ETServices')} &nbsp;</p>
              {this.renderServices()}
            </div>
          </div>
        </div>
        <div id="accordion" className="mb-5">
          <div className="card headerCard">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">{t('profileModal')} <i className="fa icon"></i></button>
              </h5>
            </div>
            <div id="collapseOne" style={{ padding: "1rem" }} className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L8')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert">
                {this.renderEducation()}
              </div>
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L9')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert">
                {this.renderLenguages()}
              </div>
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L13')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert row">
                {this.renderSkill()}
              </div>
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L10')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert">
                {this.renderWorkExperience()}
              </div>
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L11')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert">
                {this.renderHighLights()}
              </div>
              <div className="section-title text-center card-body">
                <h2 className="px-3">{t('EMP_L14')}</h2>
                <span className="title-border"></span>
              </div>
              <div className="filtersInExpert">
                {this.renderUpdateAppointmentInformation()}
                {
                  this.state.expertAvailabilitites && this.renderEvents()
                }
              </div>
            </div>
          </div>
          <div className="card headerCard">
            <div className="card-header" id="headingTwo">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">{t('profilePayments')} <i className="fa icon"></i></button>
              </h5>
            </div>
            <div id="collapseTwo" style={{ padding: "1rem" }} className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
              {
                expertBalance !== undefined &&
                this.renderBalance()
              }
            </div>
          </div>
          <div className="card headerCard">
            <div className="card-header" id="headingThree">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">{t('profileAppointments')} <i className="fa icon"></i></button>
              </h5>
            </div>
            <div id="collapseThree" style={{ padding: "1rem" }} className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
              {
                appointments !== undefined ?
                  this.renderAppointments()
                  :
                  <p>{t('no_data')}</p>
              }
            </div>
          </div>
          {
            (comments && comments.length > 0) &&
            <div className="card headerCard">
              <div className="card-header" id="headingFour">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">{t('profileComments')} <i className="fa icon"></i></button>
                </h5>
              </div>
              <div id="collapseFour" style={{ padding: "1rem" }} className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                {this.renderComments()}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ExpertModalProfile;