import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Actions
import { loader, clients_information } from "./../../actions/general";
// Services
import {
  getAllClients,
  toggleClientsStatus,
  getAllClientsBySearch,
} from "./../../services/index";
// Components
import ClientTable from "./components/ClientTable";
import ClientModalProfile from "./components/ClientModalProfile";
// Externals imports
import Modal from "react-modal";
import ReactSVG from "react-svg";
// Images
import cancel from "./../../assets/images/cancel.svg";
import { DownloadCsv } from "./components/DownloadCsv";

Modal.setAppElement("#root");

class Clients extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      terms_modal: false,
      clients: undefined,
      showToggleModal: undefined,
      ToggleClient: undefined,
      fetchError: undefined,
      sort: false,
      searchBy: undefined,
    };
  }

  componentDidMount() {
    if (this.props.clients) {
      this.setState({ clients: this.props.clients });
    } else {
      this.getClients();
    }
  }

  showToggleModalFunction = (userID) => {
    let user = this.props.clients.find((o) => o.id === parseInt(userID));
    this.setState({
      showToggleModal: true,
      ToggleClient: user,
      fetchError: undefined,
    });
  };

  closeToggleModal = (userID) => {
    this.setState({
      showToggleModal: false,
      ToggleClient: undefined,
      fetchError: undefined,
    });
  };

  toogleClientStatus = async (userID) => {
    this.props.loaderStatus(true);
    let user = this.props.clients.find((o) => o.id === parseInt(userID));
    let newData = { ...user };
    if (user.active === true) {
      newData.active = false;
      user.active = false;
    } else {
      newData.active = true;
      user.active = true;
    }
    let jwt = window.sessionStorage.getItem("jwt");
    await toggleClientsStatus(jwt, newData, newData.id)
      .then((response) => {
        if (response.error) {
          this.setState({ fetchError: response.error });
        } else {
          this.setState({ fetchError: undefined });
        }
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ fetchError: error });
        this.props.loaderStatus(false);
      });
  };

  getClients = async () => {
    let jwt = window.sessionStorage.getItem("jwt");
    this.setState({ clients: undefined });
    await getAllClients(jwt)
      .then((response) => {
        this.props.clientsInformation(response);
        this.setState({ clients: this.props.clients, searchBy: undefined });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sortByName = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.clients;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
      : newData.sort((a, b) => (a.first_name < b.first_name ? 1 : -1));
    this.setState({ clients: newData, sortByType: "name" });
  };

  sortByEmail = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.clients;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) => (a.email > b.email ? 1 : -1))
      : newData.sort((a, b) => (a.email < b.email ? 1 : -1));
    this.setState({ clients: newData, sortByType: "email" });
  };

  sortByCreated = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.clients;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) =>
          new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
        )
      : newData.sort((a, b) =>
          new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
        );
    this.setState({ clients: newData, sortByType: "created" });
  };

  sortByStatus = () => {
    this.setState((prevState) => ({
      sort: !prevState.sort,
    }));
    let data = this.state.clients;
    let sortType = this.state.sort;
    let newData = [...data];
    sortType === true
      ? newData.sort((a, b) => (a.active > b.active ? 1 : -1))
      : newData.sort((a, b) => (a.active < b.active ? 1 : -1));
    this.setState({ clients: newData, sortByType: "status" });
  };

  requestData = async () => {
    this.props.loaderStatus(true);
    const { searchData, searchBy } = this.state;
    let jwt = window.sessionStorage.getItem("jwt");
    await getAllClientsBySearch(jwt, searchBy, searchData)
      .then((response) => {
        this.props.clientsInformation(response);
        this.setState({ clients: response });
        this.props.loaderStatus(false);
      })
      .catch((error) => {
        console.log(error);
        this.props.loaderStatus(false);
      });
  };

  renderFilters = () => {
    const { t } = this.props;
    const { searchBy, searchData } = this.state;
    return (
      <div className="form-inline mb-5">
        <DownloadCsv clients={this.state.clients} />
        <div className="form-group mr-3 ml-5">
          <select
            className="form-control customPointer custom-select "
            name="searchType"
            id="searchType"
            value={this.state.searchBy}
            onChange={(e) => this.setState({ searchBy: e.target.value })}
          >
            <option value={undefined} default>
              {t("selectDefault")}
            </option>
            <option value="first_name">{t("first_name")}</option>
            <option value="last_name">{t("last_name")}</option>
            <option value="email">{t("email")}</option>
            <option value="active">{t("active")}</option>
          </select>
        </div>
        {searchBy !== "active" ? (
          <div className="form-group mr-3">
            <input
              onChange={(event) =>
                this.setState({ searchData: event.target.value })
              }
              type="text"
              className="form-control"
              name="degree_title"
              id="degree_title"
              defaultValue={searchData}
              placeholder={t("searchInput")}
              disabled={searchBy === undefined}
            />
          </div>
        ) : (
          <div className="form-group mr-3">
            <select
              className="form-control custom-select"
              name="statisSelect"
              id="statisSelect"
              value={this.state.searchData}
              onChange={(e) => this.setState({ searchData: e.target.value })}
            >
              <option value="" default>
                {t("selectDefault")}
              </option>
              <option value="true" default>
                {t("activeStatus")}
              </option>
              <option value="false">{t("disabled")}</option>
            </select>
          </div>
        )}
        <button
          onClick={() => this.requestData()}
          className="btn mb-2"
          disabled={searchBy === undefined}
        >
          {t("search")}
        </button>
        {searchBy && (
          <button
            onClick={() => this.getClients()}
            type="button"
            className="re-btn btn-danger btn-round mb-2 ml-5"
            aria-label={t("clear")}
          >
            x
          </button>
        )}
      </div>
    );
  };

  renderTable = () => {
    let data = this.state.clients;
    const { t } = this.props;
    const { sort, sortByType } = this.state;
    if (data && data.length > 0) {
      return (
        <div className="expert_appointments_container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th onClick={() => this.sortByName()} className="customPointer">
                  {t("CLName")}&nbsp;&nbsp;
                  {sort && sortByType === "name" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "name" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th onClick={() => this.sortByEmail()}>
                  {t("CLEmail")}&nbsp;&nbsp;
                  {sort && sortByType === "email" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "email" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th
                  onClick={() => this.sortByCreated()}
                  className="customPointer"
                >
                  {t("ETcreated")}&nbsp;&nbsp;
                  {sort && sortByType === "created" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "created" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
                <th
                  onClick={() => this.sortByStatus()}
                  className="customPointer"
                >
                  {t("CLStatus")}&nbsp;&nbsp;
                  {sort && sortByType === "status" ? (
                    <i className="fa fa-sort-desc" aria-hidden="true"></i>
                  ) : !sort && sortByType === "status" ? (
                    <i className="fa fa-sort-asc" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-sort" aria-hidden="true"></i>
                  )}
                </th>
              </tr>
            </thead>
            <ClientTable
              itemsperpage={10}
              nocolumns={4}
              items={data}
              pagesspan={4}
              changeClientStatus={(userID) =>
                this.showToggleModalFunction(userID)
              }
              propertires={this.props}
            />
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let { showToggleModal, ToggleClient } = this.state;
    return (
      <div>
        {this.renderFilters()}
        {this.renderTable()}
        {showToggleModal && ToggleClient ? (
          <Modal
            isOpen={this.state.showToggleModal}
            onRequestClose={this.closeToggleModal}
            className="animated fadeIn fullWidthModal showExpertModal"
            overlayClassName="Overlay"
          >
            <button
              onClick={() => {
                this.closeToggleModal();
              }}
              type="button"
              className="closeButton"
              aria-label=""
              style={{ display: "inline-block" }}
            >
              <ReactSVG src={cancel} />
            </button>
            <ClientModalProfile
              changeClientStatus={(userID) => this.toogleClientStatus(userID)}
              client={ToggleClient}
              currentState={this.state}
              t={this.props.t}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

Clients.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  loaderStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loaderStatus: (value) => dispatch(loader(value)),
  clientsInformation: (value) => dispatch(clients_information(value)),
});

const mapStateToProps = ({ general }) => ({
  clients: general.clients_information,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Clients));
