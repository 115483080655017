import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Actions
import { loader, appointments_information } from "./../../actions/general";
// Services
import { getAllppointments, getServices } from './../../services/index';
// Components
// Externals imports
import Modal from 'react-modal';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
// Components
import GlobalCharts from './components/GlobalCharts';
import LineChart from './components/LineChart';
import CoachingChart from './components/CoachingChart';
import MentoringChart from './components/MentoringChart';

Modal.setAppElement('#root');

class Appointments extends Component {

    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            terms_modal: false,
            appointments: [],
            services: [],
            allAppointments: undefined,
            mentoringAppointments: undefined,
            expressAppointment: undefined,
            coachAppointment: undefined,
        }
    }

    componentDidMount() {
        this.props.loaderStatus(true);
        let jwt = window.sessionStorage.getItem('jwt');
        if (this.state.services.length <= 0) {
            getServices(jwt)
                .then(response => {
                    this.setState({ services: response });
                    this.validateAppointments();
                })
                .catch((error => {
                    console.log(error)
                }));
        }
    }

    // Convert and download CSV
    convertToCSV = (objArray) => {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ','

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }

    // Call this!!!
    exportCSVFile = () => {
        this.props.loaderStatus(true);
        let { services } = this.state;
        let itemsNotFormatted = this.state.allAppointments;
        let itemsFormatted = [];
        let fileTitle = `appointment-${moment().format('MMMM-DD-YYYY')}`;
        let headers = {
            id: 'Appointment id',
            service: "Service",
            start_date: "Start date",
            end_date: "End date",
            client_id: "Client id",
            client_name: "Client name",
            expert_id: "Expert id",
            expert_name: "Expert name",
            appointment_status: "Status",
            price: "Price"
        };
        // format the data
        // .replace(/,/g, '')
        itemsNotFormatted.forEach((item) => {
            let currentService = services.find(o => o.id === parseInt(item.service_id));
            itemsFormatted.push({
                id: item.id,
                service: currentService.type,
                start_date: moment(item.start_date).format('MMMM-DD-YYYY'),
                end_date: moment(item.end_date).format('MMMM-DD-YYYY'),
                client_id: item.client['id'],
                client_name: `${item.client['first_name'].replace(/,/g, '')} ${item.client['last_name'].replace(/,/g, '')}`,
                expert_id: item.expert['id'],
                expert_name: `${item.expert['first_name'].replace(/,/g, '')} ${item.expert['last_name'].replace(/,/g, '')}`,
                appointment_status: item.type,
                price: item.price
            });
        });

        if (headers && itemsFormatted) {
            itemsFormatted.unshift(headers);
        }

        // Convert Object to JSON
        let jsonObject = JSON.stringify(itemsFormatted);

        let csv = this.convertToCSV(jsonObject);

        let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        this.props.loaderStatus(false);
    }
    // Convert and download CSV

    validateAppointments = () => {
        if (this.props.appointmentsInfo && this.props.appointmentsInfo.length > 0) {
            let appointments = this.props.appointmentsInfo;
            this.setState({ appointments });
            setTimeout(() => {
                this.orderAppointments(appointments);
            }, 1000);
        }
        else {
            this.getAppointments();
        }
    }

    getAppointments = async () => {
        let jwt = window.sessionStorage.getItem('jwt');
        await getAllppointments(jwt)
            .then(response => {
                this.props.appointmentsInformation(response);
                this.setState({ appointments: this.props.appointmentsInfo });
                this.orderAppointments(response);
            })
            .catch(error => {
                console.log(error);
                this.props.loaderStatus(false);
            });
    }

    orderAppointments = (appointments) => {
        let { services } = this.state;
        let mentoringAppointments = [];
        let coachAppointment = [];
        let expressAppointment = [];
        let allAppointments = [];
        let openAppointments = [];
        let scheduledAppointment = [];
        let cancelledAppointments = [];
        let inprogressAppointment = [];
        let closedAppointment = [];
        let completedAppointment = [];
        appointments.sort(function (a, b) {
            return new Date(a.start_date) - new Date(b.start_date);
        });
        allAppointments = appointments;
        if (appointments && services) {
            appointments.forEach(element => {
                let currentService = services.find(o => o.id === parseInt(element.service_id));
                // console.log(currentService);
                if (currentService && currentService.type === "Coaching") {
                    coachAppointment.push(element);
                }
                else if (currentService && currentService.type === "Mentoring") {
                    mentoringAppointments.push(element);
                }
                else if (currentService && currentService.type === "ExpressAdvice") {
                    expressAppointment.push(element);
                }
            });
        }
        appointments.forEach(element => {
            if (element.type === "CancelledAppointment") {
                cancelledAppointments.push(element);
            }
            else if (element.type === "OpenAppointment") {
                openAppointments.push(element);
            }
            else if (element.type === "ScheduledAppointment") {
                scheduledAppointment.push(element);
            }
            else if (element.type === "InProgressAppointment") {
                inprogressAppointment.push(element);
            }
            else if (element.type === "ClosedAppointment") {
                closedAppointment.push(element);
            }
            else if (element.type === "CompletedAppointment") {
                completedAppointment.push(element);
            }
        });
        this.setState({ allAppointments, mentoringAppointments, expressAppointment, coachAppointment, openAppointments, scheduledAppointment, cancelledAppointments, inprogressAppointment, closedAppointment, completedAppointment });
        this.props.loaderStatus(false);
    }


    renderGlobals = () => {
        let data = this.state.appointments;
        const { t } = this.props;
        if (data.length > 0) {
            return (
                <div className="container-fluid">
                    <p className="h2 questionHeader">{t('APP_L1')}</p>
                    <div className="row">
                        <PerfectScrollbar
                            ref={(ref) => { this._scrollBarRef = ref; }}
                            className="client_payments_section_scroll w-100"
                        >
                            <GlobalCharts
                                currentState={this.state}
                            />
                        </PerfectScrollbar>
                    </div>
                </div>
            );
        }
        else {
            return <p>{t('no_data')}</p>;
        }
    }

    renderTabs = () => {
        let data = this.state.appointments;
        // console.log('renderTabs', data);
        const { t } = this.props;
        if (data.length > 0) {
            return (
                <div className="client_payments_section">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-express-tab" data-toggle="tab" href="#nav-express" role="tab" aria-controls="nav-express" aria-selected="true">{t('express_service')}</a>
                            <a className="nav-item nav-link" id="nav-mentoring-tab" data-toggle="tab" href="#nav-mentoring" role="tab" aria-controls="nav-mentoring" aria-selected="false">{t('mentoring_service')}</a>
                            <a className="nav-item nav-link" id="nav-coaching-tab" data-toggle="tab" href="#nav-coaching" role="tab" aria-controls="nav-coaching" aria-selected="false">{t('coaching_service')}</a>
                        </div>
                    </nav>
                    <PerfectScrollbar
                        ref={(ref) => { this._scrollBarRef = ref; }}
                        className="client_payments_section_scroll"
                    >
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-express" role="tabpanel" aria-labelledby="nav-express-tab">
                                <LineChart
                                    currentState={this.state}
                                />
                            </div>
                            <div className="tab-pane fade" id="nav-mentoring" role="tabpanel" aria-labelledby="nav-mentoring-tab">
                                {/* <p>{t('soon_t')}</p> */}
                                <MentoringChart
                                    currentState={this.state}
                                />
                            </div>
                            <div className="tab-pane fade" id="nav-coaching" role="tabpanel" aria-labelledby="nav-coaching-tab">
                                <CoachingChart
                                    currentState={this.state}
                                />
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
            );
        }
        else {
            return <p>{t('no_data')}</p>;
        }
    }

    render() {
        const { mentoringAppointments, expressAppointment, coachAppointment } = this.state;
        return (
            <div>
                {
                    (mentoringAppointments && expressAppointment && coachAppointment) &&
                    <div>
                        <button className="icon-button btn-round" onClick={() => this.exportCSVFile()}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                        {this.renderGlobals()}
                        < hr />
                        {this.renderTabs()}
                    </div>
                }
            </div>
        )
    }
}

Appointments.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    loaderStatus: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    loaderStatus: value => dispatch(loader(value)),
    appointmentsInformation: value => dispatch(appointments_information(value)),
});

const mapStateToProps = ({ general }) => ({
    appointmentsInfo: general.appointments_information,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Appointments));