import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Loader extends Component {
  render() {
    const { t, loader } = this.props;
    return (
      <div className={`loaderContainer ${loader ? '' : 'hidden'}`}>
        <div className="cube-wrapper">
          <div className="cube-folding">
            <span className="leaf1"></span>
            <span className="leaf2"></span>
            <span className="leaf3"></span>
            <span className="leaf4"></span>
          </div>
          <span className="loading" data-name="Loading">{t('loading')}</span>
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = ({ general }) => ({
  loader: general.loader
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Loader));