import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Externals
import ReactSVG from 'react-svg'
// Images
import key from './../../assets/images/key.svg';

class UserSettings extends Component {

  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    const { props } = this;
    const { t } = props;
    return (
      <div className="row h-100 userSettings">
        <div className="col-12">
          <div className="card customPointer d-flex flex-row align-items-center" onClick={() => props.logOutUserFunction()}>
            <ReactSVG className="svgIcon" src={key} />
            <p>{t('US_L3')}</p>
          </div>
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = ({ login }) => ({
  userLogged: login.login,
  userData: login.fetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserSettings));