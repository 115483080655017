import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Actions
import { login, login_type, user_valid } from './../../actions/login';
import { loader } from "./../../actions/general";
// Externals imports
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import QueryString from 'query-string';
import ReactSVG from 'react-svg'
// Authentication
import LinkedInPage from './../../services/linkedinAuth';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { retiveUser, getUserToken } from './../../services/index';
// components imports
import SliderComponent from './components/SliderComponent';
import DescriptionComponent from './components/DescriptionComponent';
// Images
import logo_horizontal from './../../assets/images/logo_horizontal.svg';
import logo_vertical from './../../assets/images/logo_vertical.svg';
import cancel from './../../assets/images/cancel.svg';

Modal.setAppElement('#root');

class LoginContainer extends Component {

    constructor() {
        super();
        this.state = {
            background: 'Admin',
            loading: false,
            modalIsOpen: true,
            isLoginModal: false,
            modalCustomData: '',
        }
    }

    componentDidMount() {
        this.props.loaderStatus(false);
        setTimeout(() => {
            this.setState({ modalIsOpen: false, background: 'Admin' })
        }, 100);
        let userData = window.sessionStorage.getItem('userData');
        let userIsAuthenticated = window.sessionStorage.getItem('userAuth');
        if (userData && userIsAuthenticated)
            this.authenticateUser(JSON.parse(userData), JSON.parse(userIsAuthenticated))
    }

    setLoader = value => {
        this.props.loaderStatus(value);
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    onSuccess = async user => {
        if (user.id) {
            let userType = this.state.logginAs;
            if (userType === 'Administartor') {
                user.user_type = 'Expert';
                await retiveUser(user)
                    .then(async response => {
                        if (response && response.id) {
                            this.setState({ modalIsOpen: false });
                            this.setLoader(false);
                            await getUserToken(user)
                                .then(responseJWT => {
                                    window.sessionStorage.setItem('jwt', responseJWT.jwt);
                                    this.authenticateUser(user, responseJWT.jwt);
                                });
                            // this.authenticateUser(user, response);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.onFailure(err)
                    });
            }
        }

    }

    onFailure = data => {
        const { t } = this.props;
        this.setState({ modalIsOpen: false });
        this.setLoader(false);
        this.props.loginAction(false);
        this.setState({ logginAs: '' });
        Swal.fire({
            type: 'error',
            html: `${t('loginError')}`,
            showCancelButton: false,
            cancelButtonText: '<i class="fa fa-times-circle-o "></i>',
            width: '50%'
        })
    }

    authenticateUser = (userData, userAuth) => {
        window.sessionStorage.setItem('userAuth', JSON.stringify(userAuth));
        window.sessionStorage.setItem('userData', JSON.stringify(userData));
        this.props.userValid(userAuth);
        this.props.loginAction(true);
        this.props.loginType(userData);
        this.props.history.push('/user/dashboard');
    }

    loginUserType = value => {
        this.setState({ logginAs: value })
        this.setState({ background: value });
    }

    showLoginModal = value => {
        const instance = this;
        const { t } = instance.props;
        var AdministartorContent =
            `<img class="my-2 img-fluid loginLogoImage" src="${logo_vertical}" alt="logo">
            <h2>${t('LF_3')}</h2>
            <p>${t('LF_4')}</p>`;
        this.setState({ modalCustomData: AdministartorContent, modalIsOpen: true, isLoginModal: true });
    }

    render() {
        const { t } = this.props;
        const params = QueryString.parse(window.location.search);

        return (
            <div className="fullHeight container-fluid">
                <div className="col-12 d-flex flex-row justify-content-between align-items-center loginHeader">
                    <ReactSVG
                        className="headerLogo pt-2"
                        src={logo_horizontal}
                    />
                    <div className="languageSelector">
                        <span onClick={() => this.props.changeLanguage('es')}>{t('ChangeLngEs')}&nbsp;&nbsp;</span>|
                         <span onClick={() => this.props.changeLanguage('en')}>&nbsp;&nbsp;{t('ChangeLngEn')}</span>
                    </div>
                </div>
                <div className="row loginViews">
                    <SliderComponent
                        {...this.props}
                        newData={this.props}
                        currentState={this.state}
                        loginUserType={this.loginUserType}
                        showLoginModal={this.showLoginModal}
                        showTerms={this.showTerms}
                    />
                    <DescriptionComponent
                        {...this.props}
                        showPrivacy={this.showPrivacy}
                    />
                    { this.state.modalIsOpen &&
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.closeModal}
                            className="animated fadeIn loginModal"
                            overlayClassName="Overlay"
                        >
                            <div className="py-4">
                                <button onClick={() => { this.closeModal() }} type="button" className="closeButton" aria-label="" style={{ display: "inline-block" }}><ReactSVG src={cancel} /></button>
                                <div className="modalInformation">
                                    <div className="loginBody w-100" dangerouslySetInnerHTML={{ __html: this.state.modalCustomData }}></div>
                                    <div className="modalButtonsContainer d-flex flex-row justify-content-around flex-wrap">
                                        {(params.code || params.error) ?
                                            <LinkedInPopUp /> :
                                            <LinkedInPage
                                                onSuccess={this.onSuccess}
                                                onFailure={this.onFailure}
                                                setLoader={this.setLoader}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}

LoginContainer.propTypes = {
    loginAction: PropTypes.func.isRequired,
    loginType: PropTypes.func.isRequired,
    userValid: PropTypes.func.isRequired,
    loaderStatus: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    loginAction: value => dispatch(login(value)),
    loginType: value => dispatch(login_type(value)),
    userValid: user => dispatch(user_valid(user)),
    loaderStatus: value => dispatch(loader(value)),
});

const mapStateToProps = ({ login }) => ({
    login: login.login,
    login_type: login.login_type,
    fetch_data: login.fetch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginContainer));