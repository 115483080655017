import React, { Component } from 'react'
import { Pie } from 'react-chartjs-2';

let data;

export default class GlobalCharts extends Component {

    constructor() {
        super();
        this.state = {
            arrayData: undefined
        }
    }

    componentDidMount() {
        data = {
            labels: [
                'Mentoring',
                'Express Advice',
                'Coaching',
            ],
            datasets: [{
                data: [],
                backgroundColor: [
                    '#002e49',
                    '#FF7801',
                    '#1fb6bd',
                ],
                hoverBackgroundColor: [
                    '#002e49',
                    '#FF7801',
                    '#1fb6bd',
                ]
            }]
        };
        const { mentoringAppointments, expressAppointment, coachAppointment } = this.props.currentState;
        data.datasets[0].data.push(mentoringAppointments.length, expressAppointment.length, coachAppointment.length);
        this.setState({ arrayData: data });
    }

    render() {
        let { arrayData } = this.state;
        return (
            <div>
                {
                    arrayData &&
                    <Pie
                        data={arrayData}
                        width={400}
                        height={400}
                        options={{
                            maintainAspectRatio: false,
                        }}
                        getElementAtEvent={(elems) => {
                            console.log(elems);
                        }}
                    />
                }
            </div>
        )
    }

}