import React from 'react';
import logo from './../../../assets/images/logo_horizontal.svg';

const DescriptionComponent = (props) => {
    const { t } = props;

    return (
        <div className="col-sm-12 col-md-6 descriptionLogin flexElementColumn align-items-end pr-5">
            <img src={logo} className="img-fluid" alt="main logo" />
            <h2>{t('Welcome')}</h2>
            <p>{t('WelcomDescription')}</p>
        </div>
    )
}

export default DescriptionComponent;