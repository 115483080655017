import React from 'react';
// Externals
import ReactSVG from 'react-svg'
// Images
import logo_horizontal from './../../../assets/images/logo_horizontal.svg';

const Header = (props) => {
  const { t } = props;
  return (
    <div className="col-12 d-flex flex-row justify-content-between align-items-center">
      <ReactSVG
        className="headerLogo py-3 d-none d-md-block"
        src={logo_horizontal}
        onClick={() => {}}
      />
      <div className="languageSelector">
        <span className="d-none" onClick={() => props.changeLanguage('es')}>{t('ChangeLngEs')}&nbsp;&nbsp;</span>|
        <span onClick={() => props.changeLanguage('en')}>&nbsp;&nbsp;{t('ChangeLngEn')}</span>
      </div>
      <i 
      className="fa fa-bars headerLogo py-3 d-lg-none" 
      aria-hidden="true"
      onClick={() => props.showMenu()}
      ></i>
    </div>
  )
}

export default Header;