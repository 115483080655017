import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/es';

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: 'Completo',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
    {
      label: 'Cancelado',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(0, 46, 73,0.4)',
      borderColor: 'rgba(0, 46, 73,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(0, 46, 73,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(0, 46, 73,1)',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
    {
      label: 'Abierto',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(255, 120, 1,0.4)',
      borderColor: 'rgba(255, 120, 1,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(255, 120, 1,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(255, 120, 1,1)',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
    {
      label: 'Cerrado',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(255, 190, 11,0.4)',
      borderColor: 'rgba(255, 190, 11,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(255, 190, 11,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(255, 190, 11,1)',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
    {
      label: 'Agendado',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(105, 105, 179,0.4)',
      borderColor: 'rgba(105, 105, 179,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(105, 105, 179,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(105, 105, 179,1)',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
    {
      label: 'En progreso',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(168, 87, 81,0.4)',
      borderColor: 'rgba(168, 87, 81,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(168, 87, 81,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(168, 87, 81,1)',
      pointHoverBorderColor: 'rgba(255,255,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: []
    },
  ]
};

export default class CoachingChart extends Component {

  constructor() {
    super();
    this.state = {
      arrayData: undefined,
      jan: undefined,
      feb: undefined,
      mar: undefined,
      apr: undefined,
      may: undefined,
      jun: undefined,
      jul: undefined,
      aug: undefined,
      sep: undefined,
      oct: undefined,
      nov: undefined,
      dec: undefined,
    }
  }

  componentDidMount() {
    const dataArray = this.props.currentState['coachAppointment'];
    if (dataArray && dataArray.length > 0) {
      let jan = [], feb = [], mar = [], apr = [], may = [], jun = [], jul = [], aug = [], sep = [], oct = [], nov = [], dec = [];
      dataArray.forEach(element => {
        moment(element.start_date).get('month') === 0 ?
          jan.push(element) :
          moment(element.start_date).get('month') === 1 ?
            feb.push(element) :
            moment(element.start_date).get('month') === 2 ?
              mar.push(element) :
              moment(element.start_date).get('month') === 3 ?
                apr.push(element) :
                moment(element.start_date).get('month') === 4 ?
                  may.push(element) :
                  moment(element.start_date).get('month') === 5 ?
                    jun.push(element) :
                    moment(element.start_date).get('month') === 6 ?
                      jul.push(element) :
                      moment(element.start_date).get('month') === 7 ?
                        aug.push(element) :
                        moment(element.start_date).get('month') === 8 ?
                          sep.push(element) :
                          moment(element.start_date).get('month') === 9 ?
                            oct.push(element) :
                            moment(element.start_date).get('month') === 10 ?
                              nov.push(element) :
                              dec.push(element);
      });
      this.setState({ jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec });
    }
  }

  setGraphData = () => {
    const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec } = this.state;
    this.newData(jan);
    this.newData(feb);
    this.newData(mar);
    this.newData(apr);
    this.newData(may);
    this.newData(jun);
    this.newData(jul);
    this.newData(aug);
    this.newData(sep);
    this.newData(oct);
    this.newData(nov);
    this.newData(dec);


    return (
      <Line
        width={400}
        height={120}
        data={data}
      />
    );

    // jan.forEach(appointment => {
    //   console.log(appointment);

    // })
  }

  newData = (month) => {
    let complete = 0, cancell = 0, open = 0, close = 0, shcedule = 0, inprogress = 0;
    month.forEach(appointment => {
      if (appointment.type === "CancelledAppointment") {
        cancell++
      }
      else if (appointment.type === "OpenAppointment") {
        open++
      }
      else if (appointment.type === "ScheduledAppointment") {
        shcedule++
      }
      else if (appointment.type === "InProgressAppointment") {
        inprogress++
      }
      else if (appointment.type === "ClosedAppointment") {
        close++
      }
      else if (appointment.type === "CompletedAppointment") {
        complete++
      }
    });
    try {data.datasets[0].data.push(complete);}catch(err){}
    try {data.datasets[1].data.push(cancell);}catch(err){}
    try {data.datasets[2].data.push(open);}catch(err){}
    try {data.datasets[3].data.push(close);}catch(err){}
    try {data.datasets[4].data.push(shcedule);}catch(err){}
    try {data.datasets[5].data.push(inprogress);}catch(err){}
    complete = 0; cancell = 0; open = 0; close = 0; shcedule = 0; inprogress = 0;
  }

  render() {
    const { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec } = this.state;
    return (
      <div>
        {(jan && feb && mar && apr && may && jun && jul && aug && sep && oct && nov && dec) &&
          this.setGraphData()
        }
      </div>
    );
  }

}