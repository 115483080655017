import { LOADER, EXPERTS_INFORMATION, CLIENTS_INFORMATION, APPOINTMENTS_INFORMATION } from './../constants';

export const general = (state = {}, action) => {

  switch (action.type) {
    case LOADER:
      return { ...state, loader: action.payload }

    case EXPERTS_INFORMATION:
      return { ...state, experts_information: action.payload }

    case CLIENTS_INFORMATION:
      return { ...state, clients_information: action.payload }

    case APPOINTMENTS_INFORMATION:
      return { ...state, appointments_information: action.payload }

    default:
      return { ...state }
  }
}