import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from './../constants';

export const profile = (state = {}, action) => {

    switch (action.type) {
        case GET_USER_PROFILE:
            return { ...state, user_profile: action.payload }

        case UPDATE_USER_PROFILE:
            return { ...state, user_profile: action.payload }

        default:
            return { ...state }
    }
}