export const retiveUser = async userData => {
  var fetchInformation = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userData)
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/authentications`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getUserToken = async userData => {
  let auth = {
    provider: userData.provider,
    id: userData.id,
    email: userData.email,
    token: userData.token,
    user_type: userData.user_type
  };
  var fetchInformation = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ auth })
  };

  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/user_token`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const userAdminProfile = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/experts`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const getAllExperts = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/experts`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const getAllUsersBySearch = async (jwt, type, value) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/experts?${type}=${value}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const getAllClientsBySearch = async (jwt, type, value) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/clients?${type}=${value}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const toggleExpertStatus = async (jwt, userData, user_id) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "PATCH",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userData)
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/experts/${user_id}`;
  return fetch(proxyurl + url, fetchInformation)
    .then(resp => resp.json())
    .catch(error => console.log(error));
};

export const getAllClients = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/clients`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const toggleClientsStatus = async (jwt, userData, user_id) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "PATCH",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userData)
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/clients/${user_id}`;
  return fetch(proxyurl + url, fetchInformation)
    .then(resp => resp.json())
    .catch(error => console.log(error));
};

export const getAllppointments = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/appointments`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const getExpertAppointments = async (jwt, expert_id) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/appointments?expert_id=${expert_id}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => {
    return resp.json();
  });
};

export const getExpertBalance = async (jwt, ID) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/expert_balances/${ID}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const logoutUser = async jwt => {
  var bearer = "Bearer " + JSON.parse(jwt);
  var fetchInformation = {
    method: "DELETE",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };

  var proxyurl = process.env.REACT_APP_PROXY_URL;

  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/sessions`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getUserRates = async (jwt, userId) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/reviews/${userId}/user_reviews`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getServices = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/services`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getExpertAvailabilitiesForAppointments = async (
  jwt,
  expert_id,
  interval,
  date
) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };

  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/expert_appointments/?expert_id=${expert_id}&interval=${interval}&date=${date}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const updateAppointment = async (jwt, event) => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "PATCH",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(event)
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  // var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/appointments/${event.id}`;
  // Enviar toda la cita completa con los datos a modificar
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/admin/appointments/${event.id}`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getJobFunctions = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/job_functions/`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};

export const getIndustries = async jwt => {
  var bearer = "Bearer " + jwt;
  var fetchInformation = {
    method: "GET",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json"
    }
  };
  var proxyurl = process.env.REACT_APP_PROXY_URL;
  var url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/industries/`;
  return fetch(proxyurl + url, fetchInformation).then(resp => resp.json());
};