import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { login } from './login';
import { general } from './general';
import { profile } from './profile';

export default combineReducers({
    form: formReducer,
    login,
    general,
    profile,
});