import React from 'react';

const sliderComponent = (props) => {
    const { currentState, t } = props;

    return (
        <div className={"col-sm-12 col-md-6 flexElementColumn login " + currentState.background}>
            <div className="header">
                <h1> {t('SC_L1')}</h1>
                <p className="subheader">{t('SC_L2')}</p>
            </div>
            <div className="buttonsContainer w-100">
                <button onClick={() => { props.loginUserType('Administartor'); props.showLoginModal('Administartor') }} className="active">{t('SC_L3')}</button>
            </div>
        </div>
    )
}

export default sliderComponent;