import { LOGIN, LOGOUT, LOGIN_TYPE, USER_VALID } from './../constants';

export const login = (state = {}, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, login: action.payload }

        case LOGOUT:
            return { ...state, login: false }

        case LOGIN_TYPE:
            return { ...state, login_type: action.payload }

        case USER_VALID:
            return { ...state, user_auth: action.payload }

        default:
            return { ...state }
    }
}